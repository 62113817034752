<template>
    <div class="bg-gradient h-screen">
        <div class="bg-login h-screen">
            <router-view />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoginLayout',

  components: {

  },

  setup() {
    return {

    };
  },
});
</script>

<style scoped>
.bg-login {
    background: url('/brand/login/login_bck.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-gradient {
    background: rgb(234,108,64);
    background: linear-gradient(135deg, rgba(234,108,64,0.5) 0%, rgba(249,200,70,0.7) 35%, rgba(249,200,70,0.5) 75%, rgba(7,30,100,0.50) 100%);
}
</style>