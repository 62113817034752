import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class RegisterService {
    /**
     * Send email register OTP to user
     * @param {{email: String}} obj
     * @return {{result: Number, message: String, data: Object}}
     */
    static async sendRegisterEmailOTP(obj) {
        return axios
        .post('/v1/auth/register/flow/send/email', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Send SMS register OTP to user
     * @param {{phone: Number}} obj
     * @return {{result: Number, message: String, data: Object}}
     */
    static async sendRegisterSMSOTP(obj) {
        return axios
        .post('/v1/auth/register/flow/send/phone', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Verify email register OTP to user
     * @param {{email: String, code: String}} obj
     * @return {{result: Number, message: String, data: Object}}
     */
    static async verifyRegisterEmailOTP(obj) {
        return axios
        .post('/v1/auth/register/flow/verify/email', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Verify SMS register OTP to user
     * @param {{phone: Number, code: String}} obj
     * @return {{result: Number, message: String, data: Object}}
     */
    static async verifyRegisterSMSOTP(obj) {
        return axios
        .post('/v1/auth/register/flow/verify/phone', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Registers an user with the flow method
     * @param {Object} obj The register object
     * @param {String} obj.userName The user nickname
     * @param {String} obj.name The user name
     * @param {String} obj.surName The user surname
     * @param {String} obj.password The user password
     * @param {String} obj.canSendRelatedOffers If user has accepted offers
     * @param {String} obj.canSendCommercialInfo If user has accepted commercial info
     * @param {String} obj.canShareDataWithThirdParties If user has accepted sharing data with thirds
     * @param {String} obj.email User email. Not mandatory, verifiable data. 
     * @param {String} obj.emailVerificationCode User email verification code. Only mandatory if an email is set. 
     * @param {Number} obj.phone User phone. Not mandatory, verifiable data.
     * @param {String} obj.phoneVerificationCode User phone verification code. Only mandatory if a phone is set. 
     * @return {{result: Number, message: String, data: Object}}
     */
    static async register(obj) {
        return axios
        .post('/v1/auth/register/flow/', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }
}