<template>
    <div class="custom-opt">
        <v-otp-input ref="otpInput" inputClasses="otp-input" separator=""
                     :numInputs="6" :shouldAutoFocus="true" :isInputNum="true"
                     :conditionalClass="['otp-one', 'otp-two', 'otp-three', 'otp-four', 'otp-five', 'otp-six']"
                     :placeholder="['', '', '', '', '', '']" autocomplete="one-time-code"
                     @on-change="handleOnChange" @on-complete="handleOnComplete" />
    </div>
</template> 

<script>
import { watch, ref } from 'vue';
import VOtpInput from 'vue3-otp-input';

export default {
    components: {
        VOtpInput
    },
    props: { 
        'modelValue': String, 
    },
    emits: ['update:modelValue', 'completed'],
    setup(props, context) {
        const otpInput = ref(null);

        const handleOnComplete = (value) => {
            context.emit('update:modelValue', value);
            context.emit('completed', value);
        };

        const handleOnChange = (value) => {
            context.emit('update:modelValue', value);
        };

        const clearInput = () => {
            otpInput.value.clearInput();
        };

        watch(() => props.modelValue, async (first) => {
            if(first == '') {
                clearInput();
            }
        });

        return { handleOnComplete, handleOnChange, clearInput, otpInput };
    }
};
</script>

<style scoped>

</style>

<style>
.custom-opt .otp-input {
  width: 100%;
  padding: 8px 0px;
  margin: 0 7px 0 0;
  font-size: 22px;
  font-weight: 900;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  font-family: inherit;
}
/* Background colour of an input field with value */
.custom-opt .otp-input.is-complete {
  background-color: #fffdf7;
}
.custom-opt .otp-input::-webkit-inner-spin-button,
.custom-opt .otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-opt input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.custom-opt.p-invalid input {
    border-color: #cb0b0b;
    border-width: 2px;
}
</style>