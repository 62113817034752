/**
 * @returns {boolean}
 * @param {string} url 
 */
export function redirect(url) {
    // Normal
    window.location.href = url;

    // Method 1
    setTimeout(function(){ 
        window.location.href = url;
    }, 100);

    // Method 2
    setTimeout(function(){ 
        window.top.location.href = url;
    }, 200);

    // Method 3
    setTimeout(function(){ 
        window.location.replace(url);
    }, 200);

    // Method 4
    setTimeout(function(){ 
        var link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }, 750);

    // Probably no bueno
    setTimeout(function(){ 
        self.location = url;
        top.location = url;
    }, 1200);
} 