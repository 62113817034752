<template>
    <div class="loginevol">
        <Dialog v-model:visible="properties.isVisible" :closeOnEscape="properties.isDiscardable && !properties.isLoading"
            :modal="true" :dismissableMask="false" position="center" :draggable="false" :showHeader="false"
            style="width: 380px;" :breakpoints="{ '640px': '100vw' }" :contentStyle="{ height: 'auto' }"
            contentClass="p-0 m-0 border-round over" class="overflow-hidden loginModalDialog">
            <!-- This logo will not show because of overflow hidden. Consider future -m-1
            getting out of the box positioning with javascript -->
            <div class="fixed w-full flex absolute justify-content-center logoposition">
                <Image :src="'/brand/logo/MainLogo.png'" :srcset="'/brand/logo/MainLogo@0.5x.png' + ' 0.5x ' +
                    '/brand/logo/MainLogo@2x.png' + ' 2x '" class="flex justify-content-center logoimg"
                    style="height: 40px;" />
            </div>
            <!-- Close dialog symbol -->
            <div v-if="properties.isDiscardable" class="absolute text-right w-full top-0 right-0 mt-2 mr-2">
                <Button icon="pi pi-times" class="p-button-rounded w-2rem h-2rem closeDialogButton"
                    @click.prevent="closeDialog" />
            </div>
            <!-- Loading overlay -->
            <transition name="fade">
                <div v-if="properties.isLoading"
                    class="flex align-items-center align-content-center w-full h-full absolute z-1 p-4">
                    <div class="absolute w-full h-full bg-white-alpha-70 opacity-90 c-bg-blur" />
                    <div class="flex-column flex align-items-center align-content-center w-full 
                                h-full justify-content-center z-2" style="min-height: 200px">
                        <ProgressSpinner style="width:50px; height:50px" strokeWidth="6" fill="transparent"
                            animationDuration="2.5s" aria-label="Custom ProgressSpinner" class="w-full z-0 nobefore" />
                        <div v-if="properties.isLoadingHanged" @click="$router.go()"
                            class="mt-3 text-sm text-center bg-white">
                            Algo salió mal.
                            <span class="cursor-pointer underline z-3">
                                Por favor, pulsa aquí para recargar la página.
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
            <!-- Animated resizer -->
            <animated-resize class="overflow-hidden">
                <!-- Login screens -->
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_LOGIN" class="overflow-hidden">
                        <LoginDialog v-on:is-loading="(val) => { properties.isLoading = val; }"
                            v-on:navPasswordRecovery="properties.screen.actual = screens.UNAUTH_RECOVERPASSWORD"
                            v-on:nav-login-complete="properties.screen.actual = screens.AUTHENTICATED"
                            v-on:nav-register="properties.screen.actual = screens.UNAUTH_REGISTER_FLOW" />
                    </div>
                </transition>
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.AUTHENTICATED" class="overflow-hidden">
                        <AuthenticatedDialog v-on:is-loading="(val) => { properties.isLoading = val; }"
                            v-on:hide-login="properties.isVisible = false" />
                    </div>
                </transition>
                <!-- Password recovery screens -->
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_RECOVERPASSWORD"
                        class="d-flex flex-column overflow-hidden">
                        <PasswordRecoverySelector v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN"
                            v-on:is-loading="(val) => { properties.isLoading = val; }"
                            v-on:nav-password-recovery-otp="properties.screen.actual = screens.UNAUTH_RECOVERPASSWORD_OTP" />
                    </div>
                </transition>
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_RECOVERPASSWORD_OTP"
                        class="d-flex flex-column overflow-hidden">
                        <PasswordRecoveryOtp v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN"
                            v-on:navPasswordRecovery="properties.screen.actual = screens.UNAUTH_RECOVERPASSWORD"
                            v-on:nav-password-recovery-complete="properties.screen.actual = screens.UNAUTH_RECOVERPASSWORD_CONFIRM"
                            v-on:is-loading="(val) => { properties.isLoading = val; }" />
                    </div>
                </transition>
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_RECOVERPASSWORD_CONFIRM"
                        class="d-flex flex-column overflow-hidden">
                        <PasswordRecoveryCompleteDialog v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN" />
                    </div>
                </transition>
                <!-- Register screens -->
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_REGISTER_FLOW"
                        class="d-flex flex-column overflow-hidden">
                        <RegisterDialog v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN"
                            v-on:nav-register-otp="properties.screen.actual = screens.UNAUTH_REGISTER_OTP"
                            v-on:is-loading="(val) => { properties.isLoading = val; }" />
                    </div>
                </transition>
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_REGISTER_OTP"
                        class="d-flex flex-column overflow-hidden">
                        <RegisterOTPDialog v-on:is-loading="(val) => { properties.isLoading = val; }"
                            v-on:nav-register="properties.screen.actual = screens.UNAUTH_REGISTER_FLOW"
                            v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN"
                            v-on:nav-register-completed="properties.screen.actual = screens.UNAUTH_REGISTER_SUCCESS" />
                    </div>
                </transition>
                <transition :name="properties.screen.isGoingFoward ? 'outintrans' : 'inouttrans'"
                    class="slide-ontop d-flex flex-column">
                    <div v-if="properties.screen.actual == screens.UNAUTH_REGISTER_SUCCESS"
                        class="d-flex flex-column overflow-hidden">
                        <RegisterCompleted v-on:is-loading="(val) => { properties.isLoading = val; }"
                            v-on:nav-login="properties.screen.actual = screens.UNAUTH_LOGIN"
                            v-on:nav-login-complete="properties.screen.actual == screens.AUTHENTICATED" />
                    </div>
                </transition>
            </animated-resize>
        </Dialog>
    </div>
</template> 

<script>
import { reactive, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import AnimatedResize from '@/components/_common/layout/animatedResize.vue';

// Login parts
import LoginDialog from '@/components/login/loginmodal/LoginDialog.vue';
import AuthenticatedDialog from '@/components/login/loginmodal/AuthenticatedDialog.vue';
import PasswordRecoverySelector from '@/components/login/loginmodal/PasswordRecoverySelectorDialog.vue';
import PasswordRecoveryOtp from '@/components/login/loginmodal/PasswordRecoveryOTPDialog.vue';
import PasswordRecoveryCompleteDialog from '@/components/login/loginmodal/PasswordRecoveryCompleteDialog.vue';
import RegisterDialog from '@/components/login/loginmodal/RegisterDialog.vue';
import RegisterOTPDialog from '@/components/login/loginmodal/RegisterOTPDialog.vue';
import RegisterCompleted from '@/components/login/loginmodal/RegisterCompleted.vue';

// Prime
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';

// Stores
import { useSessionStore } from '@/store/data/login/userSession';

// Bus component
import useEmitter from '@/helpers/composables/useEmitter';

export default {
    components: {
        AnimatedResize, ProgressSpinner,
        // Login parts
        LoginDialog, AuthenticatedDialog,
        PasswordRecoverySelector, PasswordRecoveryOtp, PasswordRecoveryCompleteDialog,
        RegisterDialog, RegisterOTPDialog, RegisterCompleted,
        // Prime
        Dialog, Image, Button
    },
    setup() {
        const userSessionStore = useSessionStore();

        /*  This login widget has to have two behaviours:
            When in login page, overlay should not be closed except after a sucessful login.
            When not in login page, overlay can be closed. isVisible and isDiscardable controls this.
        */
        const screens = reactive({
            UNAUTH_LOGIN: 50,
            UNAUTH_REGISTER_FLOW: 100,
            UNAUTH_REGISTER_OTP: 110,
            UNAUTH_REGISTER_SUCCESS: 120,
            UNAUTH_TWOSTEP_SELECTOR: 190,
            UNAUTH_TWOSTEP_SMS: 191,
            UNAUTH_TWOSTEP_MAIL: 192,
            UNAUTH_RECOVERPASSWORD: 200,
            UNAUTH_RECOVERPASSWORD_OTP: 280,
            UNAUTH_RECOVERPASSWORD_CONFIRM: 290,
            AUTHENTICATED: 1000,
        });

        const properties = reactive({
            isVisible: false,
            isDiscardable: true,
            isLoading: false,
            isLoadingHanged: false,
            isLoadingHangedTimeout: null,
            screen: {
                actual: screens.UNAUTH_LOGIN,
                previous: screens.UNAUTH_LOGIN,
                isGoingFoward: true,
            }
        });

        // Watch when we change screen to perform animation
        watch(() => properties.screen.actual, (newValue, oldValue) => {
            if (newValue != oldValue) {
                properties.screen.previous = oldValue;
                properties.screen.isGoingFoward = oldValue > newValue;
            }
        });

        // Watch when loading active to reset hang timer
        watch(() => properties.isLoading, (newValue, oldValue) => {
            if (newValue && !oldValue) {
                properties.isLoadingHangedTimeout =
                    setTimeout(() => { properties.isLoadingHanged = true; }, 15000);
            } else {
                if (properties.isLoadingHangedTimeout != null) {
                    clearTimeout(properties.isLoadingHangedTimeout);
                }
                properties.isLoadingHanged = false;
            }
        });

        // Watch page change to cancel loading
        watch(() => properties.screen.actual, () => {
            properties.isLoading = false;
        });

        // Watch password recovery option type change and reset validation
        watch(() => properties.isLoading, async (newValue, oldValue) => {
            if (oldValue != newValue) {
                properties.isLoadingHanged = false;
            }
        });

        onMounted(() => {
            if (useRoute().meta?.bindLogin == true) {
                properties.isVisible = true;
                properties.isDiscardable = false;
            }

            if (userSessionStore.sessionState.auth.state == userSessionStore.authState.AUTHENTICATED) {
                properties.screen.actual = screens.AUTHENTICATED;
            }
        });

        // Watch login status to change screen if necesary
        watch(() => userSessionStore.sessionState.auth.state, (newvalue, oldvalue) => {
            // Login
            if (newvalue == userSessionStore.authState.AUTHENTICATED &&
                oldvalue == userSessionStore.authState.UNATHENTITCATED) {
                properties.screen.actual = screens.AUTHENTICATED;
            }

            if (newvalue == userSessionStore.authState.UNATHENTITCATED &&
                oldvalue == userSessionStore.authState.AUTHENTICATED) {
                properties.screen.actual = screens.UNAUTH_LOGIN;
            }
        });

        // Listen to login dialog calls
        const emitter = useEmitter();
        emitter.on('show-login-dialog', () => {
            properties.isVisible = true;
        });

        // Close dialog when not cautive
        const closeDialog = function () {
            properties.isVisible = false;
        };

        return {
            properties,
            closeDialog,
            screens,
        };
    }
};
</script>

<style scoped lang="scss">
.logoposition {
    top: -60px;
    z-index: 1102;
    position: fixed;
}

.c-bg-blur {
    backdrop-filter: grayscale(100%);
}

.loginCloseCross {
    font-size: 1em;
}

.closeDialogButton {
    border-width: 0px;
    background-color: transparent !important;
    box-shadow: 0 0 0 0;
}

.closeDialogButton:hover {
    background: #EEE !important;
}
</style>

<style lang="scss">
.p-progress-spinner::before {
    display: none !important;
}

.loginModalDialog a {
    color: #1d357f;
}

.logoimg {
    height: 40px;
}
</style>