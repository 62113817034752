import { defineStore } from 'pinia';
import { reactive } from 'vue';

import PasswordRecoveryService from '@/services/api/data/login/passwordRecovery';

export const usePasswordRecoveryStore = defineStore('passwordRecoveryStore', () => {
    const recoveryOptions = {
        EMAIL: 0,
        SMS: 1,
    };
    
    const recoveryStateDefault = () => ({
        selectedRecoveryOption: recoveryOptions.EMAIL,
        phone: '',
        email: '',
        password: '',
        clear: function() {
            recoveryState.email = '';
            recoveryState.phone = '';
            recoveryState.selectedRecoveryOption = recoveryOptions.EMAIL;
        },
        reset: function() {
            Object.assign(recoveryState, recoveryStateDefault());
        }
    });

    const recoveryState = reactive(recoveryStateDefault());

    const sendEmailRecovery = async function(email) {
        recoveryState.clear();
        recoveryState.selectedRecoveryOption = recoveryOptions.EMAIL;
        recoveryState.email = email;

        let _response = 
            await PasswordRecoveryService.sendRecoveryEmailOTP({
                'email': recoveryState.email
            });

        return _response;
    };

    const sendSMSRecovery = async function(phone) {
        recoveryState.clear();
        recoveryState.selectedRecoveryOption = recoveryOptions.SMS;
        recoveryState.phone = parseInt(phone);

        let _response = 
            await PasswordRecoveryService.sendRecoverySMSOTP({
                'phone': recoveryState.phone
            });

        return _response;
    };

    const resendRecovery = async function() {
        // For mail
        if(recoveryState.selectedRecoveryOption == recoveryOptions.EMAIL &&
           recoveryState.email != '') {
            var response = await sendEmailRecovery(recoveryState.email);
            return response;
        }

        // For SMS
        if(recoveryState.selectedRecoveryOption == recoveryOptions.SMS &&
            recoveryState.phone != '') {
            var response2 = await sendSMSRecovery(recoveryState.phone);
            return response2;
        }

        return {
            'result': 500, 'message': 'No established recovery data in store', data: {}
        };
    };

    const RecoverPassword = async function(password, resetCode) {
        recoveryState.password = password;
        let _response = 
            await PasswordRecoveryService.recoverPassword({
                'newPassword': recoveryState.password,
                'phone': recoveryState.phone == '' ? null : recoveryState.phone,
                'email': recoveryState.email == '' ? null : recoveryState.email,
                'resetCode': resetCode
            });

        return _response;
    };
  
    return { 
        recoveryState, recoveryStateDefault, recoveryOptions,
        sendEmailRecovery, sendSMSRecovery, resendRecovery, RecoverPassword
    };
});