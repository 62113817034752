<template>
    <div class="px-5 py-5">
        <h2 class="my-0 mb-2">Regístrate</h2>
        <div class="text-left mt-2">
            <div class="text-sm" variant="text">¡Te estás uniendo a la comunidad de chollos dónde todos colaboran para
                aprobechar las mejores ofertas!</div>
        </div>
        <form ref="registerForm" class="">
            <InlineMessage severity="error" v-if="registerData$v.$errors.length > 0"
                class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
                <span v-for="error of registerData$v.$errors" :key="error.$uid" class="text-sm line-height-1">
                    <strong>{{ error.$message }}. </strong>
                </span>
            </InlineMessage>
            <div class="mb-2 mt-2">
                <SelectButton v-model="registerData.registermethod" :options="registerOptions" optionLabel="name"
                    class="small p-selectbutton-sm c-login-select p-button-sm">
                    <template #option="options">
                        <i :class="options.option.icon" class="text-sm pr-2"></i>
                        <span :class="p - button - label" class="text-sm">{{ options.option.name }}</span>
                    </template>
                </SelectButton>
            </div>
            <span v-if="registerData.registermethod.value == 'phone'" class="mt-2 p-float-label">
                <MobileInput class="w-full p-inputtext-sm" v-model="registerData.phone" :class="{ 'p-invalid': false }" />
            </span>
            <span v-if="registerData.registermethod.value == 'email'" class="mt-2 p-float-label">
                <InputText id="register_username" v-model.lazy="registerData.email" class="w-full p-inputtext-sm"
                    @blur="false" :class="{ 'p-invalid': false }" autocomplete="off" />
                <label for="register_username">Correo electrónico</label>
            </span>
            <span class="mt-2 p-float-label">
                <InputText id="login_password" type="password" v-model="registerData.password" class="w-full p-inputtext-sm"
                    @blur="false" :class="{ 'p-invalid': false }" autocomplete="new-password" />
                <label for="login_password">La contraseña que deseas</label>
            </span>
            <span class="mt-2 p-float-label">
                <InputText id="login_password_confirm" type="password" v-model="registerData.passwordCheck"
                    class="w-full p-inputtext-sm" @blur="false" :class="{ 'p-invalid': false }"
                    autocomplete="new-password" />
                <label for="login_password_confirm">Repite tu contraseña</label>
            </span>
            <div class="text-left mt-2">
                <div class="text-sm" variant="text">
                    Introduce un apodo (o nickname). Esto es lo que saldrá cuándo dejes un comentario y será
                    tu nombre de usuario. Es lo que los demás verán.
                </div>
            </div>
            <span class="mt-2 p-float-label">
                <InputText id="register_nickname" v-model.lazy="registerData.nickname" class="w-full p-inputtext-sm"
                    @blur="false" :class="{ 'p-invalid': false }" autocomplete="off" />
                <label for="register_nickname">Tu apodo</label>
            </span>
            <span class="mt-2 p-float-label">
                <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                    @click.prevent="registerAction">
                    ¡Regístrate!
                </Button>
            </span>
        </form>
        <hr class="c-sep-line my-4" />
        <span class="mt-3 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check"
                v-on:click="$emit('navLogin', $event)">
                <b>Volver a iniciar sesión</b>
            </Button>
        </span>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from 'vue';

// Components
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import { PrimeIcons } from 'primevue/api';
import MobileInput from '@/components/_common/forms/mobileInput.vue';
import InlineMessage from 'primevue/inlinemessage';

// Validation
// Validation
import { useVuelidate } from '@vuelidate/core';
import {
    required, requiredIf, minLength, email, sameAs,
    maxLength, helpers as vlhelpers
} from '@vuelidate/validators';
import { username, complexpassword, usernameregistered } from '@/helpers/validators/user';
import { dummyValidator } from '@/helpers/validators/dummy';

// Store 
import { useRegisterStore } from '@/store/data/login/register';

export default {
    components: {
        Button, InputText, SelectButton, MobileInput, InlineMessage
    },
    emits: ['isLoading', 'navLogin', 'navRegisterOtp'],
    setup(props, context) {
        // Load stores
        const registerStore = useRegisterStore();

        // Register model
        const registerOptions = [
            { name: 'Con email', icon: PrimeIcons.AT, value: 'email' },
            { name: 'Con teléfono', icon: PrimeIcons.PHONE, value: 'phone' },
        ];

        const registerDataDefault = () => ({
            registermethod: registerOptions[0],
            email: '',
            phone: '',
            password: '',
            passwordCheck: '',
            nickname: '',
            clearOnChangeMethod: function () {
                registerData.email = '';
                registerData.phone = '';
            },
            reset: function () {
                Object.assign(registerData, registerDataDefault());
            }
        });

        const registerData = reactive(registerDataDefault());
        const registerForm = ref(null);

        watch(() => registerData.registermethod, (newValue, oldValue) => {
            if (newValue.value !== oldValue.value) {
                registerData.clearOnChangeMethod();
                registerData$v.value.$reset();
            }
        });

        // Validation model
        // Login validation
        const registerData_v = {
            email: {
                requiredIf: vlhelpers.withMessage('Introduce un email',
                    requiredIf(function () {
                        return registerData.registermethod.value == 'email';
                    })
                ),
                email: vlhelpers.withMessage('Introduce un email válido', email),
                minLength: vlhelpers.withMessage('Email demasiado corto', minLength(3)),
                maxLength: vlhelpers.withMessage('Email demasiado largo', maxLength(60)),
            },
            phone: {
                requiredIf: vlhelpers.withMessage('Introduce un número de teléfono',
                    requiredIf(function () {
                        return registerData.registermethod.value == 'phone';
                    })
                ),
                minLength: vlhelpers.withMessage('Teléfono demasiado corto', minLength(8)),
                maxLength: vlhelpers.withMessage('Teléfono demasiado largo', maxLength(16)),
            },
            password: {
                required: vlhelpers.withMessage('La contraseña no debe estar vacía', required),
                minLength: vlhelpers.withMessage('La contraseña debe tener al menos 8 caracteres', minLength(8)),
                maxLength: vlhelpers.withMessage('Contraseña demasiado larga', maxLength(60)),
                complexpassword: vlhelpers.withMessage(
                    'Contraseña demasiado simple: Debe incluir una mayúscula, una minúscula y un número o símbolo al menos'
                    , complexpassword(8)
                ),
            },
            passwordCheck: {
                sameAsPassword: vlhelpers.withMessage('Las contraseñas no coinciden',
                    sameAs(computed(() => { return registerData.password; }))),
            },
            nickname: {
                required: vlhelpers.withMessage('Introduce un apodo', required),
                username: vlhelpers.withMessage('El usuario debe contener sólamente letras y números', username),
                minLength: vlhelpers.withMessage('El apodo debe tener al menos tres caracteres', minLength(3)),
                maxLength: vlhelpers.withMessage('El apodo debe tener como máximo 60 caracteres', maxLength(60)),
                usernameregistered: vlhelpers.withMessage('El nombre de usuario ya está en uso', vlhelpers.withAsync(usernameregistered))
            },
            server: { dummyValidator }
        };

        const registerData$er = ref({});
        const registerData$v = useVuelidate(registerData_v, registerData, { $rewardEarly: true, $externalResults: registerData$er, $autoDirty: true });

        // Register method
        const registerAction = async function () {
            context.emit('isLoading', true);

            registerData$v.value.$clearExternalResults();
            let status = await registerData$v.value.$validate();
            if (status) {
                let isOTPSended = false;
                registerStore.registerState.userName = registerData.nickname;
                registerStore.registerState.password = registerData.password;

                // Clear other verifiable data
                registerStore.registerState.resetVerifiableData();

                if (registerData.registermethod.value == 'email') {
                    let responseEmail = await registerStore.sendEmailOTP(registerData.email);
                    if (responseEmail.result == 204) isOTPSended = true;
                }

                if (registerData.registermethod.value == 'phone') {
                    let responsePhone = await registerStore.sendSMSOTP(parseInt(registerData.phone));
                    if (responsePhone.result == 204) isOTPSended = true;
                }

                if (isOTPSended) {
                    context.emit('navRegisterOtp');
                } else {
                    registerData$er.value = {
                        server: 'Hubo un error al intentar enviar el código. Por favor, vuelva a intentarlo',
                    };
                }
            }

            context.emit('isLoading', false);
        };

        return {
            registerAction,
            registerData, registerOptions, registerForm,
            registerData$v
        };
    }
};
</script>

<style scoped>
.p-selectbutton-sm :deep(.p-button) {
    padding: 0.3em 0.6em;
}

.c-sep-line {
    background-color: #ccc;
    height: 0.05em;
    border: 0;
}
</style>