import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    // Main web layout
    {
        path: '/',
        name: 'Inicio',
        component: () => import('@/views/main/MainPageView.vue'),
        // children: [{ path: "", component: () => import("pages/Index.vue") }],
    },
    // Deal
    {
        path: '/deal/:id?',
        name: 'Deal',
        component: () => import('@/views/main/deals/DealView.vue'),
    },
    // Deal publisher 
    {
        path: '/deal/dealeditor/:id?',
        name: 'DealEditor',
        component: () => import('@/views/main/deals/DealPublish.vue'),
    },
    // Admin portal
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/views/main/admin/AdminView.vue'),
        children: [
            {
                path: 'users', name: 'AdminUsers',
                component: () => import(
                    '@/views/main/admin/sub/UsersView.vue'
                ),
                children: [
                    {
                        path: 'all', name: 'AdminUsersAll',
                        component: () => import(
                            '@/components/admin/users/List.vue')
                    },
                    {
                        path: ':id', name: 'AdminUsersEditCreate',
                        component: () => import(
                            '@/components/admin/users/EditCreate.vue'),
                        children: [
                            {
                                path: 'data', name: 'AdminUserBasicData',
                                component: () => import(
                                    '@/components/admin/users/editcreate/BasicData.vue')
                            },
                            {
                                path: 'vdata', name: 'AdminUserVerifiableData',
                                component: () => import(
                                    '@/components/admin/users/editcreate/VerifiableData.vue')
                            },
                            {
                                path: 'perm', name: 'AdminUserPermissions',
                                component: () => import(
                                    '@/components/admin/users/editcreate/Permissions.vue')
                            },
                        ]
                    },
                ]
            },
            {
                path: 'enterprises', name: 'AdminEnterprises',
                component: () => import(
                    '@/views/main/admin/sub/EnterprisesView.vue'
                ),
                children: [
                    {
                        path: 'all', name: 'AdminEnterprisesAll',
                        component: () => import(
                            '@/components/admin/enterprises/List.vue')
                    },
                    {
                        path: ':id', name: 'AdminEnterprisesEditCreate',
                        component: () => import(
                            '@/components/admin/enterprises/EditCreate.vue'),
                        children: [
                            {
                                path: 'data', name: 'AdminEnterpriseBasicData',
                                component: () => import(
                                    '@/components/admin/enterprises/editcreate/Data.vue')
                            },
                            {
                                path: 'perm', name: 'AdminEnterprisePermissions',
                                component: () => import(
                                    '@/components/admin/enterprises/editcreate/Permissions.vue')
                            },
                            {
                                path: 'referrals', name: 'AdminEnterpriseReferrals',
                                component: () => import(
                                    '@/components/admin/enterprises/editcreate/Referrals.vue')
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // Login modal
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/main/login/LoginModal.vue'),
        meta: {
            layout: 'LoginLayout',
            bindLogin: true,
        }
    },

    // TODO: Remove
    // Test page
    {
        path: '/test',
        name: 'Test',
        component: () => import('@/views/main/TestComponent.vue'),
    },

    // This is for handle 404
    {
        path: '/:catchAll(.*)*',
        component: () => import('@/views/errors/HTTP404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (to.path === from.path && to.fullPath !== from.fullPath) {
            // If only the params change, we don't want to scroll to top
            return false;
        }
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            // Scroll to top
            window.scrollTo(0, 0);

            // Scroll to top (Inner layout)
            document.documentElement.scrollTop = 0; // HTML Root element
            document.body.scrollTop = 0; // Body root element
        }
    }
});

export default router;
