<template>
    <div class="px-5 py-5">
        <div class="w-full flex justify-content-center mt-2 mb-4">
            <Image :src="'/brand/logo/MainLogo.png'" :srcset="'/brand/logo/MainLogo@0.5x.png' + ' 0.5x ' +
                    '/brand/logo/MainLogo@2x.png' + ' 2x '"
                    height="40" alt="Logo de Holachollo" 
                    class="flex justify-content-center"/>
        </div> 
        <div class="text-left mt-2 mb-4">
            <div class="text-sm" variant="text">
                <span><b>¡Ya está!</b>. Ya eres parte de la comunidad de chollos. ¡Recuerda tu </span>
                <span v-if="![null, ''].includes(registerStore.registerState.email)">
                    correo <b>{{ registerStore.registerState.email }}</b>
                </span>
                <span v-if="![null, 0].includes(registerStore.registerState.phone)">
                    teléfono <b>+{{ registerStore.registerState.phone }}</b>
                </span>
                <span> y la contraseña que has introducido para iniciar sesión en otros PCs, smartphones o tabletas!</span>
            </div>
        </div>
        <span class="mt-2 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                    @click.prevent="completeRegisterLogin">
                ¡Vamos allá!
            </Button>
        </span>
    </div>
</template>

<script>
// Store 
import { useRegisterStore } from '@/store/data/login/register';
import { useSessionStore } from '@/store/data/login/userSession';

// Components
import Button from 'primevue/button';
import Image from 'primevue/image';

export default {
    components: {
        Button, Image
    },
    emits: ['isLoading', 'navLogin', 'navLoginComplete'],
    setup(props, context) {
        // Load stores
        const registerStore = useRegisterStore();
        const sessionStore = useSessionStore();

        const completeRegisterLogin = async function() {
            context.emit('isLoading', true);

            // Auto login
            let lmethod = 'usernameoremail';
            let ldata = ''; let lpass = '';

            if(![null, ''].includes(registerStore.registerState.email)) {
                lmethod = 'usernameoremail';
                ldata = registerStore.registerState.email;
                lpass = registerStore.registerState.password;
            }

            if(![null, 0].includes(registerStore.registerState.phone)) {
                lmethod = 'phone';
                ldata = registerStore.registerState.phone;
                lpass = registerStore.registerState.password;
            }

            await sessionStore.login(lmethod, ldata, lpass, true);

            if(sessionStore.sessionState.auth.state == sessionStore.authState.AUTHENTICATED) {
                // Authenticated. Consume register. 
                registerStore.registerState.reset();
                context.emit('navLoginComplete', false);
            } else {
                // Fallback in case login fails
                context.emit('navLogin', false);
            }

            context.emit('isLoading', false);
        };

        return {
            completeRegisterLogin,
            registerStore,
        };
    }
};
</script>

<style scoped>

</style>