import { defineStore } from 'pinia';
import UserDataService from '@/services/api/data/user/userData';

import { useRequest, clearCache } from 'vue-request';
import PaginationObject from '@/helpers/url/pagination';
import UserSearchModel from '@/services/models/user/userSearchModel';

export const useUserDataStore = defineStore('userDataStore', () => {
    /**
     * Obtain an user
     * @param {String} id 
     * @return {{response: {result: Number, message: String, data: Object | null}}, loading: Boolean, error: String}}
     */
    const getUser = function(id = null) {
        if(id == null) { id = 'me'; }
        const { data, loading, error } = useRequest(
            async () => await UserDataService.getUser(id),
            { cacheKey: 'user/' + id }
        );

        return { response: data, loading, error };
    };

    /**
     * Obtain users
     * @param {PaginationObject} pagination 
     * @param {UserSearchModel} filters 
     * @return {{response: {result: Number, message: String, data: Object | null}}, loading: Boolean, error: String}}
     */
    const getUsers = function(pagination = null, filters = null) {
        if(pagination == null) pagination = new PaginationObject();
        if(filters == null) filters = new UserSearchModel();
        const { data, loading, error } = useRequest(
            async () => await UserDataService.getUsers(pagination.toAPIQueryString(), filters),
            { cacheKey: 'users/?' + pagination.toAPIQueryString() + '&' + filters.ToURLParams() }
        );

        return { response: data, loading, error };
    };

    /**
     * Delete user
     * @param {String} id 
     * @return {{response: {result: Number, message: String, data: Object | null}}, loading: Boolean, error: String}}
     */
    const deleteUser = function(userid, password) {
        const { data, loading, error } = useRequest(
            async () => {
                clearCache('user/' + userid);
                return await UserDataService.deleteUser(userid, password);
            }
        );

        return { response: data, loading, error };
    };

    /**
     * Patch user
     * @param {String} id 
     * @param {Object} patch
     * @return {{response: {result: Number, message: String, data: Object | null}}, loading: Boolean, error: String}}
     */
    const patchUser = function(id, patch) {
        const { data, loading, error } = useRequest(
            async () => {
                clearCache('user/' + id);
                return await UserDataService.patchUser(id, patch);
            },
        );

        return { response: data, loading, error };
    };

    return {
        getUser, getUsers, deleteUser, patchUser
    };
});