export const OrderComponent = {
    None: 0,
    Ascendent: 1,
    Descendent: -1,
};

export const ToOrderString = function(obj) {
    let properties = Object.getOwnPropertyNames(obj);

    let orderString = '';
    properties.forEach(function(prop) {
        if(obj[prop] == OrderComponent.Ascendent) {
            orderString += prop + '.asc,';
        } else if(obj[prop] == OrderComponent.Descendent) {
            orderString += prop + '.desc,';
        }
    });

    if(orderString.length > 0) {
        if (orderString.charAt(orderString.length - 1) == ',') {
            orderString = orderString.substring(0, orderString.length - 1);
        }
    }

    return orderString;
};

export const FromOrderString = function(str, obj) {
    for (let key in obj) {
        obj[key] = OrderComponent.None;
    }

    let itemsOrder = str.split(',');

    itemsOrder.forEach(item => {
        let [nameItem, orderItem] = item.split('.');
        let nameItemLower = nameItem.toLowerCase();

        for (let key in obj) {
            if (key.toLowerCase() === nameItemLower) {
                if (orderItem.toLowerCase() === 'asc') {
                    obj[key] = OrderComponent.Ascendent;
                }
                else {
                    obj[key] = OrderComponent.Descendent;
                }
                break;
            }
        }
    });
};

export class OrderBase {
    ToOrderString() {
      return ToOrderString(this);
    }
    
    FromOrderString(str) {
      return FromOrderString(str, this);
    }
}

/**
 * Base class that provides ordering functionality for derived classes.
 * The `order` property is initialized with an instance created by the `createOrderInstance` method, 
 * which must be overridden by the child class.
 */
export class OrderableBase {
    /**
     * Initializes a new instance of the OrderableBase class.
     * The `order` property is initialized by calling the `createOrderInstance` method.
     */
    constructor() {
      this.order = this.createOrderInstance();
    }
  
    /**
     * Method to create an instance of the order model.
     * This method must be overridden by the child class.
     * 
     * @returns {Object} The order model instance.
     * @throws {Error} Throws an error if not overridden by the child class.
     */
    createOrderInstance() {
        // Must be overriden by child class
        throw new Error('Must be overriden by child class');
    }
  
    /**
     * Gets the current order as a string representation.
     * 
     * @returns {string} A string representation of the order.
     */
    get orderBy() {
      return this.order.ToOrderString();
    }
  
    /**
     * Sets the order from a string representation.
     * 
     * @param {string} order The string representation of the order.
     */
    set orderBy(order) {
      this.order.FromOrderString(order);
    }
}