import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class UserSessionService {
    /**
     * Send email recovery OTP to user
     * @return {{result: Number, message: String, data: Object}}
     */
    static async login(obj) {
        return axios
        .post('/v1/auth/login', obj, { dontAddBearer: true })
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Send refresh token
     * @return {{result: Number, message: String, data: Object}}
     */
    static async refreshLogin(obj) {
        return axios
        .post('/v1/auth/refresh', obj, { dontAddBearer: true })
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }
}