import queryString from 'query-string';

/**
 * Object to handle pagination with the server.
 */
export default class PaginationObject {
    /** @type {Boolean} */
    _floatpage = false;     // This variable is for not sending conflicting information on page change.
                            // If this variable is true, we don't send conflicting information. See 'toApiQueryString'
                            // We use this to mark variables like 'skip' to unknown. 
    
    /**
     * @type {Number}
     * @description The current page number.
     */
    _page = undefined;
    get page() {
        return this._page;
    }
    set page(value) {
        if(this._page != value) {
            this._page = value;
            this._floatpage = true;
        }
    }

    /**
     * @type {Number}
     * @description The number of elements per page.
     */
    elementsPerPage = undefined;
   /**
     * @type {Number}
     * @description The total number of elements.
     */
    totalElements = undefined; 
    /**
     * @type {Number}
     * @description The total number of pages.
     */
    totalPages = undefined;
    /**
     * @type {Number}
     * @description The index of the first element on the current page.
     */
    startElement = undefined;
    /**
     * @type {Number}
     * @description The index of the last element on the current page.
     */
    endElement = undefined;
    /**
     * @type {Number}
     * @description The number of elements to skip (used for pagination).
     */
    skip = undefined;

    /**
     * Constructor for PaginationObject.
     * @param {Object} [config] - The configuration object.
     * @param {Number|undefined} [config.page] - The initial page number.
     * @param {Number|undefined} [config.elementsPerPage] - The initial number of elements per page.
     * @param {Number|undefined} [config.totalElements] - The initial number of elements per page.
     * @param {Number|undefined} [config.totalPages] - The total number of pages.
     * @param {Number|undefined} [config.startElement] - The index of the first element on the current page.
     * @param {Number|undefined} [config.endElement] - The index of the last element on the current page.
     * @param {Number|undefined} [config.skip] - The number of elements to skip
     */
    constructor(config = {}) {
        this.page = config.page ?? this.page;
        this.elementsPerPage = config.elementsPerPage ?? this.elementsPerPage;
        this.totalElements = config.totalElements ?? this.totalElements;
        this.totalPages = config.totalPages ?? this.totalPages;
        this.startElement = config.startElement ?? this.startElement;
        this.endElement = config.endElement ?? this.endElement;
        this.skip = config.skip ?? this.skip;
    }

    /**
     * Converts the pagination object to an API query string.
     * @returns {String} The query string for the API.
     */
    toAPIQueryString = function() {
        return queryString.stringify(this.toAPIParams());
    };

    /**
     * Converts the pagination object to an API backend compatible parameters object.
     * @returns {Object} The parameters for the API.
     * @property {Number} page - The current page number.
     * @property {Number} elementsPerPage - The number of elements per page.
     * @property {Number} [totalElements] - The total number of elements.
     * @property {Number} [totalPages] - The total number of pages.
     * @property {Number} [startElement] - The index of the first element on the current page.
     * @property {Number} [endElement] - The index of the last element on the current page.
     * @property {Number} [skip] - The number of elements to skip.
     */
    toAPIParams = function() {
        return {
            'page': this.page,
            'elementsPerPage': this.elementsPerPage,
            'totalElements': !this._floatpage ? this.totalElements : undefined,
            'totalPages': !this._floatpage ? this.totalPages : undefined,
            'startElement': !this._floatpage ? this.startElement : undefined,
            'endElement': !this._floatpage ? this.endElement : undefined,
            'skip': !this._floatpage ? this.skip : undefined
        };
    }

    /**
     * Populates the pagination object from API data.
     * @param {Object} apipagination - The pagination data from the API.
     * @property {Number} apipagination.page - The current page number.
     * @property {Number} apipagination.elementsPerPage - The number of elements per page.
     * @property {Number} apipagination.startElement - The index of the first element on the current page.
     * @property {Number} apipagination.endElement - The index of the last element on the current page.
     * @property {Number} apipagination.skip - The number of elements to skip.
     * @property {Number} apipagination.totalElements - The total number of elements.
     * @property {Number} apipagination.totalPages - The total number of pages.
     */
    fromAPI = function(apipagination) {
        this._page = apipagination.page;
        this.elementsPerPage = apipagination.elementsPerPage;
        this.startElement = apipagination.startElement;
        this.endElement = apipagination.endElement;
        this.skip = apipagination.skip;
        this.totalElements = apipagination.totalElements;
        this.totalPages = apipagination.totalPages;
        this._floatpage = false; // Data recived from API is realiable
    }

    /**
     * Converts the pagination object to a front-end query string.
     * @returns {String} The query string for the front-end.
     */
    toFrontQuery = function() {
        let query = queryString.stringify(this.toFront());
        return query;
    };

    /**
     * TODO: What the hell is this method doing
     * @returns {Object} The parameters for the front-end.
     * @property {Number} page - The current page number.
     * @property {Number} pg_elems - The number of elements per page.
     */
    toFront = function() {
        return {
            'page': this.page,
            'pg_elems': this.elementsPerPage,
        };
    }

    /**
     * Populates the pagination object from front-end query data.
     * @param {String|Object} query - The query data from the front-end.
     * @property {Number} [query.page] - The current page number.
     * @property {Number} [query.pg_elems] - The number of elements per page.
     * @property {Number} [query.pg_start] - The index of the first element on the current page.
     * @property {Number} [query.pg_end] - The index of the last element on the current page.
     * @property {Number} [query.pg_skip] - The number of elements to skip.
     */
    fromFrontQuery = function(query) {
        let obj = query;
        if(typeof query === 'string' || query instanceof String) {
            obj = queryString.parse(query);
        }
        this.page = obj.page != undefined ? obj.page : this.page;
        this.elementsPerPage = obj.pg_elems != undefined ? obj.pg_elems : this.elementsPerPage;
        this.startElement = obj.pg_start != undefined ? obj.pg_start : this.startElement;
        this.endElement = obj.pg_end != undefined ? obj.pg_end : this.endElement;
        this.skip = obj.pg_skip != undefined ? obj.pg_skip : this.skip;
    }
}