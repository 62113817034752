import { defineStore } from 'pinia';
import { reactive } from 'vue';

import RegisterService from '@/services/api/data/login/register';

export const useRegisterStore = defineStore('registerStore', () => {
    const registerStateDefault = () => ({
        // Register data
        userName: '', name: '', surName: '', password: '',
        canSendRelatedOffers: false, canSendCommercialInfo: false, canShareDataWithThirdParties: false,

        // Verifiable data
        email: null, emailVerificationCode: null,
        phone: null, phoneVerificationCode: null,
        resetVerifiableData() {
            registerState.email = null; registerState.emailVerificationCode = null;
            registerState.phone = null; registerState.phoneVerificationCode = null;
        },
        reset: function() {
            Object.assign(registerState, registerStateDefault());
        }
    });

    const registerState = reactive(registerStateDefault());

    // OTPs
    /**
     * Send email register OTP to user
     * @param {String} email
     * @return {{result: Number, message: String, data: Object}}
     */
    const sendEmailOTP = async function(email) {
        registerState.email = email;

        let _response = 
            await RegisterService.sendRegisterEmailOTP({
                'email': registerState.email
            });

        return _response;
    };

    /**
     * Send email register OTP to user
     * @param {Number} phone
     * @return {{result: Number, message: String, data: Object}}
     */
    const sendSMSOTP = async function(phone) {
        registerState.phone = parseInt(phone);

        let _response = 
            await RegisterService.sendRegisterSMSOTP({
                'phone': registerState.phone
            });

        return _response;
    };

    // Check OTPs
    /**
     * Send email register OTP to user
     * @param {String} email
     * @param {String} otp
     * @return {{result: Number, message: String, data: Object}}
     */
    const checkEmailOTP = async function(email, otp) {
        registerState.email = email;
        registerState.emailVerificationCode = otp;

        let _response = 
            await RegisterService.verifyRegisterEmailOTP({
                'email': registerState.email,
                'code': registerState.emailVerificationCode
            });

        return _response;
    };

    /**
     * Send email register OTP to user
     * @param {Number} phone
     * @param {String} otp
     * @return {{result: Number, message: String, data: Object}}
     */
    const checkSMSOTP = async function(phone, otp) {
        registerState.phone = phone;
        registerState.phoneVerificationCode = otp;

        let _response = 
            await RegisterService.verifyRegisterSMSOTP({
                'phone': registerState.phone,
                'code': registerState.phoneVerificationCode
            });

        return _response;
    };

    // VerifyVerifiableData
    const checkVerifiableData = async function() {
        let isVerified = false;

        // Email
        if(registerState.email != null) {
            var mailResponse = await checkEmailOTP(registerState.email, registerState.emailVerificationCode);
            if(mailResponse.result < 300 && mailResponse.data.isCorrect) {
                isVerified = true;
            }
        }

        // Phone
        if(registerState.phone != null) {
            var phoneResponse = await checkSMSOTP(registerState.phone, registerState.phoneVerificationCode);
            if(phoneResponse.result < 300 && phoneResponse.data.isCorrect) {
                isVerified = true;
            } 
        }
        
        return isVerified;
    };

    /**
     * Send email register OTP to user
     * @return {{result: Number, message: String, data: Object}}
     */
    const register = async function() {
        let _response = 
            await RegisterService.register({
                'userName': registerState.userName,
                'name': registerState.name,
                'surName': registerState.surName,
                'password': registerState.password,
                'canSendCommercialInfo': registerState.canSendCommercialInfo,
                'canSendRelatedOffers': registerState.canSendRelatedOffers,
                'canShareDataWithThirdParties': register.canShareDataWithThirdParties,
                
                'email': registerState.email,
                'emailVerificationCode': registerState.emailVerificationCode,

                'phone': registerState.phone,
                'phoneVerificationCode': registerState.phoneVerificationCode
            });

        return _response;
    };

    return {
        registerState,
        sendEmailOTP, sendSMSOTP, checkEmailOTP, checkSMSOTP, checkVerifiableData,
        register
    };
});