<template>
    <div class="px-5 py-5">
        <h2 class="my-0 mb-2">Introduce el código</h2>
        <div class="text-left mt-2 mb-2">
            <div class="text-sm" variant="text">
                <b>¡Ya sólo falta esto!</b> Introduce el código que hemos enviado a
                <span v-if="![null, ''].includes(registerStore.registerState.email)">
                    <b>{{ registerStore.registerState.email }}</b>
                </span>
                <span v-if="![null, 0].includes(registerStore.registerState.phone)">
                    <b>+{{ registerStore.registerState.phone }}</b>
                </span>
                <span>. </span>
                <a class="underline cursor-pointer" v-on:click="$emit('navRegister', $event)">
                    Si este dato es incorrecto cambia la dirección o teléfono de registro haciendo click aquí.
                </a>
            </div>
        </div>
        <InlineMessage severity="error" v-if="registerOTPData$v.otp.$errors.length > 0"
            class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
            <span v-for="error of registerOTPData$v.otp.$errors" :key="error.$uid" class="text-sm">
                <strong>{{ error.$message }}.</strong>
            </span>
        </InlineMessage>
        <OtpInput class="mt-2" v-model="registerOTPData.otp"
            :class="{ 'p-invalid': registerOTPData$v.otp.$errors.length }" />
        <div class="text-left mt-2 mb-3">
            <div class="text-sm cursor-pointer text-color-secondary" variant="text" disabled="true" :class="{
                'otpdisabled': registerOTPData.otpTimeReset > 0,
                'underline': registerOTPData.otpTimeReset < 1
            }" @click="resendOTPCode">
                <a>
                    Volver a enviar código
                    <span v-if="registerOTPData.otpTimeReset > 0">
                        <span>(en </span>
                        <span>{{ registerOTPData.otpTimeResetData.min }}:</span>
                        <span>{{ registerOTPData.otpTimeResetData.sec }}</span>
                        <span>)</span>
                    </span>
                </a>
            </div>
        </div>
        <InlineMessage severity="error"
            v-if="registerOTPData$v.acceptTerms.$errors.length > 0 || registerOTPData$v.canSendRelatedOffers.$errors.length > 0"
            class="mb-2 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
            <span
                v-for="error of registerOTPData$v.acceptTerms.$errors.concat(registerOTPData$v.canSendRelatedOffers.$errors)"
                :key="error.$uid" class="text-sm">
                <strong>{{ error.$message }}.</strong>
            </span>
        </InlineMessage>
        <div class="field-checkbox mb-2">
            <Checkbox Id="accept-terms" v-model="registerOTPData.acceptTerms" :binary="true" />
            <label for="accept-terms" class="text-sm">
                Acepto los términos y condiciones y la política de privacidad
            </label>
        </div>
        <div class="field-checkbox">
            <Checkbox Id="accept-relatedOffers" v-model="registerOTPData.canSendRelatedOffers" :binary="true" />
            <label for="accept-relatedOffers" class="text-sm">
                Quiero estar enterado de los mejores chollos y ofertas semanalmente
            </label>
        </div>
        <span class="mt-2 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                @click.prevent="registerAction">
                ¡Regístrame!
            </Button>
        </span>
        <hr class="c-sep-line my-4" />
        <span class="mt-3 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check"
                v-on:click.prevent="$emit('navLogin', $event)">
                <b>Volver a iniciar sesión</b>
            </Button>
        </span>
    </div>
</template> 

<script>
import { reactive, computed, watch } from 'vue';
import OtpInput from '@/components/_common/forms/OtpInput.vue';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';

// Validation
import { useVuelidate } from '@vuelidate/core';
import {
    required, minLength, numeric, sameAs,
    maxLength, helpers as vlhelpers
} from '@vuelidate/validators';
import { dummyValidator } from '@/helpers/validators/dummy';
import ServerMessagesAdapter from '@/helpers/validators/utils/serverValidatorMessageConversor';

// Store 
import { useRegisterStore } from '@/store/data/login/register';

export default {
    components: {
        OtpInput, Checkbox, Button, InlineMessage
    },
    emits: [
        'isLoading', 'navLogin', 'navRegister', 'navRegisterCompleted'
    ],
    setup(props, context) {
        // Load stores
        const registerStore = useRegisterStore();

        // Model
        const registerOTPDefault = () => ({
            otp: '',
            otpTimeReset: 90,
            otpTimeResetData: {
                min: computed(() => {
                    return String(Math.floor(registerOTPData.otpTimeReset / 60) % 60).padStart(2, '0');
                }),
                sec: computed(() => {
                    return String(registerOTPData.otpTimeReset % 60).padStart(2, '0');
                }),
            },

            acceptTerms: false,
            canSendRelatedOffers: false,
            reset: function () {
                Object.assign(registerOTPData, registerOTPDefault());
            }
        });

        const registerOTPData = reactive(registerOTPDefault());

        // Validation
        const registerOTPData_v = {
            otp: {
                required: vlhelpers.withMessage('Introduce el código que has recibido', required),
                numeric: vlhelpers.withMessage('El código debe contener sólo números', numeric),
                minLength: vlhelpers.withMessage('El código debe tener 6 dígitos', minLength(6)),
                maxLength: vlhelpers.withMessage('El código debe tener 6 dígitos', maxLength(6)),
            },
            acceptTerms: {
                required: vlhelpers.withMessage('Falta el dato de aceptación de términos', required),
                sameAs: vlhelpers.withMessage(
                    'Tiene que aceptar los términos de uso y la política de privacidad',
                    sameAs(true)
                ),
            },
            canSendRelatedOffers: {
                required: vlhelpers.withMessage('Falta el dato de enviar ofertas', required),
            },
            server: { dummyValidator }
        };

        const registerOTPData$er = reactive({});
        const registerOTPData$v = useVuelidate(registerOTPData_v, registerOTPData, { $rewardEarly: true, $externalResults: registerOTPData$er, $autoDirty: true });

        // OTP Time
        watch(() => registerOTPData.otpTimeReset, async (newData) => {
            if (newData > 0) {
                setTimeout(() => { registerOTPData.otpTimeReset--; }, 1000);
            }
        }, { immediate: true });

        // Resend OTP
        const resendOTPCode = async function () {
            if (registerOTPData.otpTimeReset < 1) {
                context.emit('isLoading', true);
                let OTPSent = false;

                // Email
                if (![null, ''].includes(registerStore.registerState.email)) {
                    let response = await registerStore.sendEmailOTP(registerStore.registerState.email);
                    if (response.result == 204) OTPSent = true;
                }

                // Phone
                if (![null, 0].includes(registerStore.registerState.phone)) {
                    let response = await registerStore.sendSMSOTP(registerStore.registerState.phone);
                    if (response.result == 204) OTPSent = true;
                }

                if (OTPSent) {
                    registerOTPData.otpTimeReset = registerOTPDefault().otpTimeReset;
                }

                context.emit('isLoading', false);
            }
        };

        const registerAction = async function () {
            context.emit('isLoading', true);

            registerOTPData$v.value.$clearExternalResults();
            let status = await registerOTPData$v.value.$validate();
            if (status) {
                registerStore.registerState.canSendCommercialInfo = registerOTPData.canSendCommercialInfo;

                let OTPChecked = false;

                if (![null, ''].includes(registerStore.registerState.email)) {
                    let response = await registerStore.checkEmailOTP(registerStore.registerState.email, registerOTPData.otp);
                    if (response.result == 200 && response.data.isCorrect) OTPChecked = true;
                }

                if (![null, 0].includes(registerStore.registerState.phone)) {
                    let response = await registerStore.checkSMSOTP(registerStore.registerState.phone, registerOTPData.otp);
                    if (response.result == 200 && response.data.isCorrect) OTPChecked = true;
                }

                if (!OTPChecked) {
                    registerOTPData$er.otp = 'El código es incorrecto. Por favor, asegúrate de que sea correcto.';
                }

                if (OTPChecked) {
                    let response = await registerStore.register();
                    if (response.result == 201) {
                        context.emit('navRegisterCompleted');
                    } else if (response.result == 400) {
                        registerOTPData$er.value = { server: ServerMessagesAdapter(response.data) };
                    } else {
                        registerOTPData$er.value = { server: 'Ocurrió un error al registrarse. Estos son los detalles: ' + response.message };
                    }
                }
            }

            context.emit('isLoading', false);
        };

        return {
            registerOTPData,
            registerOTPData$v,
            resendOTPCode, registerAction,
            registerStore
        };
    }
};
</script>

<style scoped></style>