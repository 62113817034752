<template>
    <div class="px-5 py-5 text-center">
        <h2 class="my-0 pb-2">Contraseña cambiada</h2>
        <div class="text-center mt-2 mb-3">
            
        </div>
        <div class="text-center mt-2 mb-2">
            <b>Hemos cambiado tu contraseña por la que has elegido.</b>
            Ahora podrás iniciar sesión con 
            <span v-if="passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.EMAIL">
                el correo <b>{{ passwordRecoveryStore.recoveryState.email }}</b>
            </span>
            <span v-if="passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.SMS">
                el teléfono <b>+{{ passwordRecoveryStore.recoveryState.phone }}</b>
            </span>
            y la nueva contraseña.
        </div>  
        <span class="mt-4 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                    @click.prevent="goToLogin">
                Ir a inicio de sesión
            </Button>
        </span>
    </div>
</template>

<script>
import Button from 'primevue/button';
import { usePasswordRecoveryStore } from '@/store/data/login/passwordRecovery';

export default {
    components: {
        Button
    },
    emits: ['navLogin',],
    setup(props, context) {
        const passwordRecoveryStore = usePasswordRecoveryStore();

        const goToLogin = function() {
            let method = '';
            if(passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.EMAIL) {
                method = 'email';
            }

            if(passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.SMS) {
                method = 'phone';
            }

            context.emit('navLogin', 
            { 
                method: method, 
                email: passwordRecoveryStore.recoveryState.email, 
                phone: passwordRecoveryStore.recoveryState.phone, 
                password: passwordRecoveryStore.recoveryState.password
            });
        };

        return { passwordRecoveryStore, goToLogin };
    }
};
</script>

<style scoped>

</style>