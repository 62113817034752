import { defineStore } from 'pinia';
import { reactive, computed, ref, watch } from 'vue';
import UserPermissionsService from '@/services/api/data/user/userPermissions';

import { useSessionStore } from '@/store/data/login/userSession';
import { LocalStorage } from 'frontend-storage';

import { useRequest } from 'vue-request';

export const useUserPermissionsStore = defineStore('userPermissionsStore', () => {
    // Stores
    const userSessionStore = useSessionStore();

    // Data
    const permState = {
        UNOBTAINED: 0,
        OBTAINED: 100,
    };

    const userPermissionsDefault = () => ({
        state: permState.UNOBTAINED,
        fromCache: false,
        date: Date.now(),
        roles: computed(() => {
            if(userPermissions.state == permState.OBTAINED 
                && permissionsData.value?.response?.data?.roles !== undefined) {
                return permissionsData.value.response.data.roles;
            } else if(permissionsRolesCache.value != []) {
                return permissionsRolesCache.value;
            } else {
                return [];
            }
        }),
        claims: computed(() => {
            if(userPermissions.state == permState.OBTAINED
                && permissionsData.value?.response?.data?.roles !== undefined) {
                return permissionsData.value.response.data.claims;
            } else if(permissionsClaimsCache.value != []) {
                return permissionsClaimsCache.value;
            } else {
                return [];
            }
        }),
        reset: function() {
            Object.assign(userPermissions, userPermissionsDefault());
        }
    });

    const userPermissions = reactive(userPermissionsDefault());
    const permissionsData = reactive({}); // Data loaded from API
    const permissionsRolesCache = ref([]);
    const permissionsClaimsCache = ref([]);

    // Get
    const getUserPermissions = function(id) {
        const { data, loading, error } = useRequest(
            async () => await UserPermissionsService.getUserRolesAndClaims(id),
            { cacheKey: 'permissions/' + id }
        );

        return { response: data, loading, error };
    };

    const getAllPermissions = function() {
        const { data, loading, error } = useRequest(
            async () => await UserPermissionsService.getAllRolesAndClaims(),
            { cacheKey: 'permissions/all' }
        );

        return { response: data, loading, error };
    };

    // Store process
    const adquireUserPermisions = function() {
        loadPermissions(); // Load permissions from cache
        let userid = userSessionStore.sessionState.user?.id;
        if(userid) {
            let permissions = getUserPermissions(userid);
            permissionsData.value = permissions;
        }
    };

    // Update permissions on token change
    watch(() => userSessionStore.sessionState.auth.data.token, () => {
        if(userSessionStore.sessionState.auth.data.token 
            && userSessionStore.sessionState.auth.state == userSessionStore.authState.AUTHENTICATED) {
            adquireUserPermisions();
        }
    });

    // Update permissions on user id change
    watch(() => userSessionStore.sessionState.user.id, () => {
        if(userSessionStore.sessionState.user.id
            && userSessionStore.sessionState.auth.state == userSessionStore.authState.AUTHENTICATED) {
            adquireUserPermisions();
        }
    });

    // Update permissions obtained when reactive populated
    watch(permissionsData, () => {
        // If permissionsData object is populated, then we set permissions to obtained
        let isLoading = permissionsData.loading == false;
        let isError = permissionsData.error != undefined;
        let isPopulated = permissionsData.value.response?.data != undefined;
        if(isPopulated && (!isLoading) && (!isError)) {
            userPermissions.state = permState.OBTAINED;
            userPermissions.fromCache = false;
            savePermisisons();
            userPermissions.date = Date.now();
        } else if(userPermissions.state == permState.OBTAINED) {
            userPermissions.state = permState.UNOBTAINED;
        }
    });

    // Functions to cache permissions to local storage
    const savePermisisons = function() {
        if (LocalStorage.isAvailable && userPermissions.state == permState.OBTAINED) {
            let lstorage = new LocalStorage('hcf_userpermissions');
            lstorage.set('roles', userPermissions.roles);
            lstorage.set('claims', userPermissions.claims);
        }
    };

    const loadPermissions = function() {
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_userpermissions');

            let userRoles = lstorage.get('roles'); // Roles
            if(Array.isArray(userRoles) && userRoles.length > 0) { 
                permissionsRolesCache.value = userRoles;
                userPermissions.fromCache = true;
            }

            let userClaims = lstorage.get('claims'); // Claims
            if(Array.isArray(userClaims) && userClaims.length > 0) {
                permissionsClaimsCache.value = userClaims;
                userPermissions.fromCache = true;
            }

            if(userPermissions.fromCache) {
                userPermissions.date = Date.now();
            }
        }
    };

    const clearPermissions = function() {
        userPermissions.state == permState.UNOBTAINED;
        permissionsRolesCache.value = [];
        permissionsClaimsCache.value = [];
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_userpermissions');
            userPermissions.date = Date.now();
            lstorage.clear();
        }
    };

    // Watch session state to clear permissions on logout
    watch(() => userSessionStore.sessionState.auth.state, (newValue, oldValue) => {
        if(newValue == userSessionStore.authState.UNATHENTITCATED &&
            oldValue == userSessionStore.authState.AUTHENTICATED) {
                clearPermissions();
            }
    });

    // Utility methods
    const hasRole = function(role = '') {
        let obtainedRole = userPermissions.roles.find(x => x.name.toLowerCase() == role.toLowerCase());
        return obtainedRole !== undefined;
    };

    const getRole = function(role = '') {
        let obtainedRole = userPermissions.roles.find(x => x.name.toLowerCase() == role.toLowerCase());
        if(obtainedRole !== undefined) {
            return obtainedRole.content;
        }
    };

    const hasClaim = function(claim = '') {
        let obtainedClaim = userPermissions.claims.find(x => x.name.toLowerCase() == claim.toLowerCase());
        return obtainedClaim !== undefined;
    };

    const getClaim = function(claim = '') {
        let obtainedClaim = userPermissions.claims.find(x => x.name.toLowerCase() == claim.toLowerCase());
        if(obtainedClaim !== undefined) {
            return obtainedClaim.content;
        }
    };

    const getUserRolesList = computed(() => {
        return userPermissions.roles.filter(obj => obj.name !== undefined).map(obj => obj.name);
    });

    const getUserClaimsList = computed(() => {
        return userPermissions.claims.filter(obj => obj.name !== undefined).map(obj => obj.name);
    });

    // Update permissions on startup
    // NOTE: Maintain method execution on build before return!
    // TODO: Can't we use onMounted() here?
    adquireUserPermisions();

    return {
        userPermissions, permState,
        getUserPermissions, getAllPermissions,
        permissionsData, permissionsRolesCache, permissionsClaimsCache,
        hasRole, getRole, hasClaim, getClaim, getUserClaimsList, getUserRolesList
    };
});