/**
 * Convert server validation errors to readable messages
 * @param {object} data Data recived from server (body)
 * @returns {string} Validation messages.
 */
export default function(data) {
    let messages = '';

    // For messages with traceId (Crashes, etc.)
    if(data.traceId != undefined) {
        messages += '[' + data.traceId + '] | ';
        let dataErrors = data.errors;
        let dataErrorsKeys = Object.keys(dataErrors);
        for(let key in dataErrorsKeys) {
            messages += '[' + dataErrorsKeys[key] +'] ';
            let error = dataErrors[dataErrorsKeys[key]];
            for(let message in error) {
                messages += error[message] + '. ';
            }
        }
    }

    // For expected messages in backend that are not handled by the frontend
    if(data.errorCode != undefined && data.errorMessage != undefined) {
        messages += data.errorMessage + ' {' + data.errorCode + '}';
    }

    return messages.trim().replace(/\.+$/, '');
}