import { parseDomain, ParseResultType } from 'parse-domain';

function extractDomain(domain) {
    let domainRegex = /^(?:https?:)?(?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/img;
    let domainregex = [...domain.matchAll(domainRegex)][0];

    if(domainregex != null && domainregex.length > 1) {
        return domainregex[1].toLowerCase();
    }

    return '';
}

/**
 * @returns {boolean}
 * @param {string} one 
 * @param {string} two 
 */
export function isSameSubdomain(one, two) {
    return extractDomain(one) == extractDomain(two);
} 

/**
 * @returns {boolean}
 * @param {string} one 
 * @param {string} two 
 */
export function isSameDomain(one, two) {
    let domainone = extractDomain(one);
    let domaintwo = extractDomain(two);

    let domainOneParseResult = parseDomain(domainone);
    let domainTwoParseresult = parseDomain(domaintwo);

    if (domainOneParseResult.type !== ParseResultType.Invalid
        && domainTwoParseresult.type !== ParseResultType.Invalid) {
        
        let isSameDomain = domainone.domain == domaintwo.domain;
        let isSameTLDs = true;
        for(let item in domainone.topLevelDomains) {
            if(domaintwo.topLevelDomains.find((obj) => obj == item) == undefined) {
                isSameTLDs = false;
                break;
            }
        }

        return isSameTLDs && isSameDomain;
    }

}