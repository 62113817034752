import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class UserPermissionsService {
    /**
     * Get All System Roles and claims
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getAllRolesAndClaims() {
        return axios
        .get('/v1/permissions')
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Check an user Roles and Claims
     * @param {String} userid 
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getUserRolesAndClaims(userid) {
        return axios
        .get(`/v1/permissions/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Add role to an user
     * @param {String} userid 
     * @param {String} rolename
     * @return {{result: Number, message: String, data: Object}}
     */
    static async addRole(userid, rolename) {
        return axios
        .post(`/v1/role/${rolename}/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Delete role to an user
     * @param {String} userid 
     * @param {String} rolename
     * @return {{result: Number, message: String, data: Object}}
     */
    static async deleteRole(userid, rolename) {
        return axios
        .delete(`/v1/role/${rolename}/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Add role to an user
     * @param {String} userid 
     * @param {String} rolename
     * @return {{result: Number, message: String, data: Object}}
     */
    static async addClaim(userid, claimname) {
        return axios
        .post(`/v1/claim/${claimname}/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Delete role to an user
     * @param {String} userid 
     * @param {String} rolename
     * @return {{result: Number, message: String, data: Object}}
     */
    static async deleteClaim(userid, claimname) {
        return axios
        .delete(`/v1/claim/${claimname}/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }
}