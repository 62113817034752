<template>
    <div ref="refName" class="position-relative" :style="style" :class="animationClasses">
        <div class="position-absolute overflow-visible my-auto" style="box-sizing: border-box;">
            <div ref="refResizer" style="box-sizing: border-box;" class="position-relative nocollapse">
                <slot style="box-sizing: border-box;" />
                <div :class="forceClasses" style="box-sizing: border-box;" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onUpdated, onUnmounted, nextTick, toRefs, watchEffect, watch, defineExpose } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

export default {
    name: 'AnimatedResizeComponent',
    props: {
        defaultHeight: {
            type: Number,
            default: 0
        },
        minHeight: {
            type: Number,
            default: 0
        },
        centerContent: {
            type: Boolean,
            default: false
        },
        overflowhidden: {
            type: Boolean,
            default: true
        },
        animationDuration: {
            type: Number,
            default: 0.4
        },
        initAnimationDuration: {
            type: Number,
            default: 0.05
        },
        animationInit: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        // Data
        const containerHeight = ref(0);
        const containerWidth = ref(0);
        const detectedHeight = ref(0);
        const detectedWidth = ref(0);
        const refName = ref(null);
        const refResizer = ref(null);
        const isInitTimeout = ref(false);

        // Convert props to refs
        const { animationDuration, initAnimationDuration, animationInit } = toRefs(props);

        
        const forceClasses = reactive({
            forceNothing: true, forceChange: false
        });
        
        const animationClasses = reactive({
            withAnimation: true, withoutanimation: false
        });
        
        const style = reactive({
            height: 'auto', 'margin-top': 'inherit', overflow: computed(() => props.overflowhidden ? 'hidden' : 'visible'),
            animationTimingFunction: 'ease-in-out',
            transition: animationDuration.value + 's',
        });

        // WatchEffect to force update the transition property based on dependencies
        watchEffect(() => {
            if(animationClasses.withAnimation) {
                style.transition = animationInit.value || isInitTimeout.value ? initAnimationDuration.value + 's' : animationDuration.value + 's';
            } else {
                style.transition = '0s';
            }
        });

        watch(animationInit, (value, oldValue) => {
            if(!value && oldValue) {
                isInitTimeout.value = true;
                setTimeout(() => {
                    isInitTimeout.value = false;
                }, animationDuration.value * 1000);
            }
        });

        // Computed
        const heightPx = computed(() => {
            return containerHeight.value + 'px';
        });

        const heightCSS = computed(() => {
            return 'height: ' + heightPx.value + ';';
        });

        const marginPx = computed(() => {
            return (containerHeight.value - detectedHeight.value) + 'px';
        });

        /***
         * Reacts and calculates actual container height and sets it as fixed height on
         * the style of the outer box so we can animate with css
         * @param {Object} object containing Number properties width and height
         * @param {Number} object.width Element width when resizing
         * @param {Number} object.height Element height when resizing
         */
        const onResize = function({ width, height }) { 
            if(width != null && height != null) {

                // First intent: Get client Width and Height
                // This return the data as integers therefore is not 100% exact
                width = refResizer.value?.clientWidth;
                height = refResizer.value?.clientHeight;

                if(width == null || height == null) {
                    return; 
                }

                // Second intent. Try to obtain REAL height and width animate-with
                // getBoundingClientRect. This method fails on load on chrome, so 
                // we only assume it is correct if result is in 1px margin of first
                // intent.
                let real_height = 0.0; let real_width = 0.0;
                let rects = refResizer.value.getBoundingClientRect();
                if(rects != null && rects != undefined) {
                    real_height = rects.height;
                    real_width = rects.width;

                    // Prevent on web load fail if it is too diferent
                    if(real_height <= height + 1 && real_height >= height - 1 &&
                    real_width <= width + 1 && real_width >= width - 1) {
                        width = real_width;
                        height = real_height;
                    }
                }

                // Height and Width defined
                containerHeight.value = Math.max(height, props.minHeight);
                containerWidth.value = width;
                detectedHeight.value = height;
                style.height = heightPx;

                // Center content
                if(props.centerContent) {
                    style['margin-top'] = marginPx;
                } else {
                    style['margin-top'] = 'inherit';
                }

                // Reset force mode
                if(forceClasses.forceChange) {
                    changeForcedMode(false);
                }
            } else {
                changeForcedMode(true);
            }
            
        };

        /***
         * Sets if the forced mode is active. Forced mode forces recalculation 
         * when changed is not detected. This is useful when there is no change
         * but we need to calculate, as in component creation on onMount()
         * @param {boolean} isActive true to force recalculation. False to return to normal mode.
         */
        const changeForcedMode = function(isActive) {
            forceClasses.forceChange = isActive;
            forceClasses.forceNothing = !isActive;
        };

        const forceRecalculation = function() {
            changeForcedMode(true);
        };

        // Lifecicle
        const initializationPerformed = ref(false);
        const initialization = function() {
            if(refResizer.value == null) {
                return;
            }

            const slotWidth = refResizer.value.clientWidth;
            const slotHeight = refResizer.value.clientHeight;
            onResize({ width: slotWidth, height: slotHeight });
            resizeObserver.value.observe(refResizer.value);
            initializationPerformed.value = true;
        }

        onMounted(() => {
            nextTick(() => {
                if(initializationPerformed.value == false) initialization();
            });
        });

        onUpdated(() => {
            nextTick(() => {
                if(initializationPerformed.value == false) initialization();
                const slotHeight = refResizer.value.clientHeight;
                if(slotHeight != detectedHeight.value) {
                    const slotWidth = refResizer.value.clientWidth;
                    onResize({ width: slotWidth, height: slotHeight });
                }
            });
        });

        onUnmounted(() => {
            resizeObserver.value = undefined;
        });

        // Observer
        const resizeObserver = ref(new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;

                if(height != detectedHeight.value || width != detectedWidth.value) {
                    onResize({ width: width, height: height });
                }
            }
        }));

        // Expose function
        defineExpose({
            forceRecalculation
        });

        return { forceClasses, animationClasses,
        containerHeight, containerWidth, detectedHeight, detectedWidth,
        style, 
        heightPx, heightCSS, marginPx,
        onResize, refName, refResizer, resizeObserver };
    }
};
</script>

<style>
.hidden-observable {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    pointer-events: none;
    display: block;
    overflow: hidden;
    opacity: 0;
}

.nocollapse {
    display: flex;
    flex-direction: column;
}

.withoutanimation {
    transition: 0s;
}

.forceNothing {
    display: none;
    height: 0px;
    width: 0px;
}

.forceChange {
    display: block;
    height: 0.0001px;
}
</style>