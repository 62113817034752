import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class PasswordRecoveryService {
    /**
     * Send email recovery OTP to user
     * @return {{result: Number, message: String, data: Object}}
     */
    static async sendRecoveryEmailOTP(obj) {
        return axios
        .post('/v1/auth/password/recover/email', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Send SMS OTP to user
     * @return {{result: Number, message: String, data: Object}}
     */
    static async sendRecoverySMSOTP(obj) {
        return axios
        .post('/v1/auth/password/recover/sms', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }

    /**
     * Send SMS OTP to user
     * @return {{result: Number, message: String, data: Object}}
     */
    static async recoverPassword(obj) {
        return axios
        .post('/v1/auth/password/reset', obj)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

}
