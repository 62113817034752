import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class PhoneService {
    /**
     * Get phone codes of countries
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getCountryCodes() {
        return axios
        .get('/v1/phone/countrycodes')
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error);
        });
    }
}
