/**
 * 
 * @param {Object} obj 
 */
export const ToURLParams = function (obj) {
    if (typeof obj !== 'object' || obj === null) {
        return '';
    }

    let propsObject = {};
    let keys = Object.keys(obj);

    // Keys
    keys.forEach((key) => {
        let value = obj[key];

        /* order of this line is essential */
        if(typeof value === 'function') return;
        if(typeof value === 'undefined') return;
        if(value instanceof Date) value = value.toISOString();
        if(typeof value === 'object') return;
        if(value === '') value = undefined;

        propsObject[key] = value;
    });

    // Relevant getters
    if(hasGetter(obj, 'orderBy')) {
        const order = obj.orderBy;
        if(order != undefined && order != '') {
            propsObject['orderBy'] = obj.orderBy;
        }
    }

    return propsObject;

};

export const ToURLQueryString = function (obj) {
    const params = ToURLParams(obj);

    return Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
};

export const inferType = function (value) {
    if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    } else if (/^-?\d+$/.test(value)) {
        return parseInt(value, 10);
    } else if (!isNaN(value) && value.trim() !== '') {
        return parseFloat(value);
    } else if (isStringADate(value)) {
        return new Date(value);
    } else {
        return value;
    }
};

export const FromURLParams = function(urlParams, obj) {
    if (typeof urlParams !== 'string' && typeof urlParams !== 'object') {
        return;
    }

    if (typeof obj !== 'object' || obj === null) {
        return;
    }

    if(typeof urlParams === 'object') {
        urlParams = new URLSearchParams(urlParams);
    }

    const params = urlParams;

    params.forEach((value, key) => {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        const inferredValue = inferType(decodedValue);

        if (Object.hasOwn(obj, decodedKey) && (typeof obj[decodedKey] === 'undefined' ||
        obj[decodedKey] === null || typeof obj[decodedKey] === typeof inferredValue)) {
            obj[decodedKey] = inferredValue;
        }
    });
};

function isStringADate(str) {
    if (typeof str !== 'string') {
      return false;
    }
  
    const date = new Date(str);
    return !isNaN(date.getTime());
}

// eslint-disable-next-line no-unused-vars
function isObjectAOrderDuck(obj) {
    return (
      typeof obj.ToOrderString === 'function' &&
      obj.ToOrderString.length === 0 &&
      typeof obj.FromOrderString === 'function' &&
      obj.FromOrderString.length === 1
    );
}

function hasGetter(obj, getterName) {
    let currentPrototype = Object.getPrototypeOf(obj);
  
    while (currentPrototype !== null) {
      const descriptor = Object.getOwnPropertyDescriptor(currentPrototype, getterName);
      if (descriptor && typeof descriptor.get === 'function') {
        return true;
      }
      currentPrototype = Object.getPrototypeOf(currentPrototype);
    }
  
    return false;
}