<template>
    <div class="px-5 py-5">
        <h2 class="my-0 pb-3">Iniciar sesión</h2>

        <!-- Login Zone -->

        <form ref="loginForm" class="">
            <div class="mb-2">
                <SelectButton v-model="loginData.loginmethod" :options="loginOptions" optionLabel="name"
                    class="small p-selectbutton-sm c-login-select p-button-sm">
                    <template #option="options">
                        <i :class="options.option.icon" class="text-sm pr-2"></i>
                        <span :class="p - button - label" class="text-sm">{{ options.option.name }}</span>
                    </template>
                </SelectButton>
            </div>
            <InlineMessage severity="error" v-if="loginData$v.$errors.length > 0"
                class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
                <span v-for="error of loginData$v.$errors" :key="error.$uid" class="text-sm line-height-1">
                    <strong>{{ error.$message }}. </strong>
                </span>
            </InlineMessage>
            <span v-if="loginData.loginmethod.value == 'phone'" class="mt-2 p-float-label">
                <MobileInput class="w-full p-inputtext-sm" v-model="loginData.phone"
                    :class="{ 'p-invalid': loginData$v.phone.$errors.length }" />
            </span>
            <span v-if="loginData.loginmethod.value == 'email'" class="mt-2 p-float-label">
                <InputText id="login_username" v-model.lazy="loginData.email" class="w-full p-inputtext-sm"
                    @blur="loginData$v.email.$validate()" :class="{ 'p-invalid': loginData$v.email.$errors.length }"
                    autocomplete="username nickname email" />
                <label for="login_username">Usuario o correo</label>
            </span>
            <span class="mt-2 p-float-label">
                <InputText id="login_password" type="password" v-model="loginData.password" class="w-full p-inputtext-sm"
                    @blur="loginData$v.password.$touch" :class="{ 'p-invalid': loginData$v.password.$errors.length }"
                    autocomplete="current-password" />
                <label for="login_password">Contraseña</label>
            </span>
            <span class="mt-2 p-float-label">
                <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                    @click.prevent="loginAction">
                    Iniciar sesión
                </Button>
            </span>

            <div class="mt-3 mb-3 text-center" v-on:click="$emit('navPasswordRecovery', $event)">
                <span class="text-xs cursor-pointer">¿Has olvidado tu contraseña?</span>
            </div>
        </form>

        <hr class="c-sep-line" />

        <!-- Register navigation Zone -->

        <div class="text-center mt-4 text-lg">
            <div class="font-semibold p-text-secondary">¿No eres aún miembro?</div>
        </div>

        <div class="text-center mt-2">
            <div class="text-sm" variant="text">¡Únete a la comunidad! No somos como otras páginas, que publican ofertas de
                unos céntimos o los referidos que les interesan. ¡Holachollo sois vosotros!</div>
        </div>

        <span class="mt-3 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check" @click="false"
                v-on:click="$emit('navRegister', $event)">
                <b>Regístrate ahora</b>
            </Button>
        </span>
    </div>
</template>

<script>
import { reactive, watch, ref } from 'vue';

// Elements
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import MobileInput from '@/components/_common/forms/mobileInput.vue';
import InlineMessage from 'primevue/inlinemessage';
import { PrimeIcons } from 'primevue/api';

// Validation
import { useVuelidate } from '@vuelidate/core';
import {
    required, requiredIf, minLength,
    maxLength, helpers as vlhelpers
} from '@vuelidate/validators';
import { usernameoremail } from '@/helpers/validators/user';
import { dummyValidator } from '@/helpers/validators/dummy';

import ServerMessagesAdapter from '@/helpers/validators/utils/serverValidatorMessageConversor';

import { useSessionStore } from '@/store/data/login/userSession';

export default {
    components: {
        // PrimeVUE
        InputText, Button, SelectButton, InlineMessage,
        // Custom
        MobileInput
    },
    emits: [
        'navPasswordRecovery', 'navRegister', 'isLoading', 'navLoginComplete'
    ],
    setup(props, context) {
        const userSessionStore = useSessionStore();

        const loginOptions = [
            { name: 'Con email', icon: PrimeIcons.AT, value: 'email' },
            { name: 'Con teléfono', icon: PrimeIcons.PHONE, value: 'phone' },
        ];

        const loginDataDefault = () => ({
            loginmethod: loginOptions[0],
            email: '',
            phone: '',
            password: '',
            clearOnChangeMethod: function () {
                loginData.email = '';
                loginData.phone = '';
            },
            reset: function () {
                Object.assign(loginData, loginDataDefault());
            }
        });

        const loginData = reactive(loginDataDefault());
        const loginForm = ref(null);

        // Login validation
        const loginData_v = {
            email: {
                requiredIf: vlhelpers.withMessage('Introduce un correo electrónico o nombre de usuario',
                    requiredIf(function () {
                        return loginData.loginmethod.value == 'email';
                    })
                ),
                usernameoremail,
                minLength: vlhelpers.withMessage('Usuario o email demasiado corto', minLength(3)),
                maxLength: vlhelpers.withMessage('Usuario o email demasiado largo', maxLength(100)),
            },
            phone: {
                requiredIf: vlhelpers.withMessage('Introduce un número de teléfono',
                    requiredIf(function () {
                        return loginData.loginmethod.value == 'phone';
                    })
                ),
                minLength: vlhelpers.withMessage('Teléfono demasiado corto', minLength(8)),
                maxLength: vlhelpers.withMessage('Teléfono demasiado largo', maxLength(16)),
            },
            password: {
                required: vlhelpers.withMessage('La contraseña no debe estar vacía', required),
                minLength: vlhelpers.withMessage('La contraseña debe tener al menos 8 caracteres', minLength(8)),
                maxLength: vlhelpers.withMessage('Contraseña demasiado larga', maxLength(60)),
            },
            server: { dummyValidator }
        };

        const loginData$er = ref({});
        const loginData$v = useVuelidate(loginData_v, loginData, { $rewardEarly: true, $externalResults: loginData$er, $autoDirty: true });

        // Login action 
        const loginAction = async function () {
            context.emit('isLoading', true);
            loginData$v.value.$clearExternalResults();
            let status = await loginData$v.value.$validate();
            if (status) {
                let method = '';
                let logindata = '';
                if (loginData.loginmethod.value == 'email') {
                    method = 'usernameoremail';
                    logindata = loginData.email;
                }
                if (loginData.loginmethod.value == 'phone') {
                    method = 'phone';
                    logindata = loginData.phone;
                }
                var _response = await userSessionStore.login(
                    method, logindata, loginData.password
                );

                if (_response.result < 299) {
                    context.emit('navLoginComplete');
                } else if (_response.result == 400) {
                    loginData$er.value = { server: ServerMessagesAdapter(_response.data) };
                } else if (_response.result == 401) {
                    loginData$er.value = { server: 'Los datos de inicio de sesión son incorrectos. Comprueba tus datos y contraseña' };
                } else {
                    loginData$er.value = { server: _response.message };
                }

                console.log(_response);
            }

            context.emit('isLoading', false);
        };

        // Watch login type change and reset validation
        watch(() => loginData.loginmethod, async (oldValue, newValue) => {
            if (oldValue.value != newValue.value) {
                loginData.clearOnChangeMethod();
                loginData$v.value.$reset();
            }
        });

        return { loginForm, loginOptions, loginData, loginData$v, loginData$er, loginAction };
    },
};
</script>

<style scoped>
.p-selectbutton-sm :deep(.p-button) {
    padding: 0.3em 0.6em;
}

.c-login-select :deep(.p-button.p-highlight) {
    background: #071e64;
    border-color: #071e64;
    color: #fff;
}

.c-sep-line {
    background-color: #ccc;
    height: 0.05em;
    border: 0;
}
</style>
