<template>
    <!-- Back interface -->
    <div class="px-5 py-5">
        <h2 class="my-0 pb-2">Recuperar contraseña</h2>
        <div class="text-left mt-2">
            <div class="text-sm" variant="text">
                ¡No pasa nada! Dinos si iniciabas sesión con tu correo, usuario o
                número de teléfono (Si tienes ambos registrados, usa cualquiera):
            </div>
        </div>
        <div class="mt-3 text-sm">
            <div class="field-radiobutton mb-2">
                <RadioButton inputId="method1" name="method" value="email" v-model="recoveryData.selectedRecoveryOption" />
                <label for="method1">Me registré con mi <b>correo electrónico</b></label>
            </div>
            <div class="field-radiobutton mb-2">
                <RadioButton inputId="method2" name="method" value="phone" v-model="recoveryData.selectedRecoveryOption" />
                <label for="method2">Me registré con mi <b>número de teléfono</b></label>
            </div>
        </div>
        <div class="text-left mt-3 text-sm">
            <div class="mb-1" variant="text">
                Para recuperar tu contraseña, introduce tu
                <span v-if="recoveryData.selectedRecoveryOption == 'email'">
                    <b>correo electrónico</b>:
                </span>
                <span v-if="recoveryData.selectedRecoveryOption == 'phone'">
                    <b>número de teléfono</b>:
                </span>
            </div>
        </div>
        <InlineMessage severity="error" v-if="recoveryData$v.$errors.length > 0"
            class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
            <span v-for="error of recoveryData$v.$errors" :key="error.$uid" class="text-sm">
                <strong>{{ error.$message }}.</strong>
            </span>
        </InlineMessage>
        <form ref="recoveryForm">
            <span v-if="recoveryData.selectedRecoveryOption == 'phone'" class="mt-2 p-float-label">
                <MobileInput class="w-full p-inputtext-sm" v-model="recoveryData.phone"
                    :class="{ 'p-invalid': recoveryData$v.phone.$errors.length }" @blur="recoveryData$v.phone.$touch" />
            </span>
            <span v-if="recoveryData.selectedRecoveryOption == 'email'" class="mt-2 p-float-label">
                <InputText id="recovery_username" v-model="recoveryData.email" class="w-full p-inputtext-sm"
                    :class="{ 'p-invalid': recoveryData$v.email.$errors.length }" @blur="recoveryData$v.email.$touch" />
                <label for="recovery_username">Correo electrónco</label>
            </span>
            <span class="mt-2 p-float-label">
                <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full" @click="recoveryAction">
                    Recuperar contraseña
                </Button>
            </span>
        </form>
        <hr class="c-sep-line my-4" />
        <span class="mt-3 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check"
                v-on:click="$emit('navLogin', $event)">
                <b>Volver a iniciar sesión</b>
            </Button>
        </span>
    </div>
</template> 

<script>
import { reactive, watch, ref } from 'vue';

// Elements
import RadioButton from 'primevue/radiobutton';
import MobileInput from '@/components/_common/forms/mobileInput.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';

// Validation
import { useVuelidate } from '@vuelidate/core';
import {
    requiredIf, email, minLength,
    maxLength, helpers as vlhelpers
} from '@vuelidate/validators';

// Store
import { usePasswordRecoveryStore } from '@/store/data/login/passwordRecovery';

export default {
    components: {
        RadioButton, MobileInput, InputText, Button, InlineMessage
    },
    emits: ['navLogin', 'navPasswordRecoveryOtp', 'isLoading'],
    setup(props, context) {
        const passwordRecoveryStore = usePasswordRecoveryStore();

        const recoveryDataDefault = () => ({
            selectedRecoveryOption: 'email',
            email: '',
            phone: '',
            clearOnChangeMethod: function () {
                recoveryData.email = '';
                recoveryData.phone = '';
            },
            reset: function () {
                Object.assign(recoveryData, recoveryDataDefault());
            }
        });

        const recoveryData = reactive(recoveryDataDefault());

        // Login validation
        const recoveryData_v = {
            email: {
                requiredIf: vlhelpers.withMessage('Introduzca su correo electrónico',
                    requiredIf(function () {
                        return recoveryData.selectedRecoveryOption == 'email';
                    })
                ),
                email: vlhelpers.withMessage('Debe ser una dirección de correo', email),
                minLength: vlhelpers.withMessage('Correo demasiado corto', minLength(3)),
                maxLength: vlhelpers.withMessage('Correo demasiado largo', maxLength(60)),
            },
            phone: {
                requiredIf: vlhelpers.withMessage('Introduzca un número de teléfono',
                    requiredIf(function () {
                        return recoveryData.selectedRecoveryOption == 'phone';
                    })
                ),
                minLength: vlhelpers.withMessage('Teléfono demasiado corto', minLength(8)),
                maxLength: vlhelpers.withMessage('Teléfono demasiado largo', maxLength(100)),
            },
        };

        const recoveryData$er = ref({});
        const recoveryData$v = useVuelidate(recoveryData_v, recoveryData, { $rewardEarly: true, $externalResults: recoveryData$er, $autoDirty: true });

        // Watch password recovery option type change and reset validation
        watch(() => recoveryData.selectedRecoveryOption, async (oldValue, newValue) => {
            if (oldValue != newValue) {
                recoveryData$v.value.$reset();
                recoveryData.clearOnChangeMethod();
            }
        });

        // Actions
        const recoveryAction = async function () {
            recoveryData$v.value.$clearExternalResults();
            let status = await recoveryData$v.value.$validate();
            if (status) {
                context.emit('isLoading', true);

                if (recoveryData.selectedRecoveryOption == 'email') {
                    let _result =
                        await passwordRecoveryStore.sendEmailRecovery(recoveryData.email);

                    if (_result.result < 299) {
                        console.log(_result.data);
                        context.emit('navPasswordRecoveryOtp');
                    } else {
                        recoveryData$er.value = { email: _result.message };
                    }
                } else if (recoveryData.selectedRecoveryOption == 'phone') {
                    let _result =
                        await passwordRecoveryStore.sendSMSRecovery(recoveryData.phone);

                    if (_result.result < 299) {
                        console.log(_result.data);
                        context.emit('navPasswordRecoveryOtp');
                    } else {
                        recoveryData$er.value = { email: _result.message };
                    }
                } else {
                    recoveryData$er.value = {
                        email: 'Opción incorrecta en la acción de recuperación: Error interno',
                    };
                }
            }

            // Stop loading
            context.emit('isLoading', false);
        };

        return {
            recoveryData, recoveryAction,
            recoveryData_v, recoveryData$v, recoveryData$er,
            passwordRecoveryStore
        };
    }
};
</script>

<style scoped></style>