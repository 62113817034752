import axios, {
    handleSuccessResponse,
    handleErrorResponse,
} from '@/services/api/index';

export default class UserDataService {
    /**
     * Check if an username is Taken
     * @param {String} username 
     * @return {{result: Number, message: String, data: Object}}
     */
    static async isUsernameTaken(username) {
        return axios
        .get('/v1/users/isUsernameTaken', 
            { params: { username: username } }
        )
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Get my user
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getMe() {
        return await this.getUser('me');
    }

    /**
     * Get an user
     * @param {String} userid 
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getUser(userid) {
        return axios
        .get(`/v1/user/${userid}`)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Search users
     * @param {PaginationObject} pagination 
     * @param {UserSearchModel} filters 
     * @return {{result: Number, message: String, data: Object}}
     */
    static async getUsers(paginationparams, filters) {
        return axios
        .post('/v1/users/?' + paginationparams, filters)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    /**
     * Delete an user
     * @param {String} userid 
     * @returns {{result: Number, message: String, data: Object}}
     */
    static async deleteUser(userid, password) {
        return axios
        .delete(`/v1/user/${userid}`, { data: { password: password } })
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

    static async patchUser(userid, patch) {
        return axios
        .patch(`/v1/user/${userid}`, patch)
        .then((response) => {
            return handleSuccessResponse(response);
        })
        .catch((error) => {
            return handleErrorResponse(error, '', false);
        });
    }

}