import { OrderComponent, ToOrderString, FromOrderString } from '@/services/models/orderDuck';
import { ToURLParams, FromURLParams } from '@/services/models/modelDuck';

export default class UserSearchModel {
    /** @type {String} */
    username = undefined;
    /** @type {Date} */
    membersincefrom = undefined;
    /** @type {Date} */
    membersinceto = undefined;

    // Communications (verifiable data)
    /** @type {String} */
    email = undefined;
    /** @type {Boolean|null} */
    emailConfirmed = null;
    /** @type {Number} */
    phone = undefined;
    /** @type {Boolean|null} */
    phoneConfirmed = null;

    // User personal information
    /** @type {String} */
    name = undefined;
    /** @type {String} */
    surname = undefined;
    /** @type {String} */
    enterpriseposition = undefined;
    /** @type {String} */
    enterprisename = undefined;

    // Order
    /** @type {UserSearchModelOrder} */
    order = new UserSearchModelOrder();

    /** @type {String} *
     * This name is obligatory */
    get orderBy() {
        return this.order.ToOrderString();
    }
    /** @type {String} *
     * This name is obligatory */
    set orderBy(order) {
        order.FromOrderString(order);
    }

    /* URL Params duck */
    ToURLParams = () => ToURLParams(this);
    FromURLParams = (params) => FromURLParams(params, this);

    /* To JSON for getter */
    toJSON() {
        return {
            ...this,
            orderBy: this.orderBy
        };
    }
}

class UserSearchModelOrder {
    membersince = OrderComponent.None;
    phone = OrderComponent.None;
    username = OrderComponent.None;
    email = OrderComponent.None;
    
    /* Order duck. Every order object has to have this in order ToURLParams to work */
    ToOrderString = () => ToOrderString(this);
    FromOrderString = (str) => FromOrderString(str, this);
}