<template>
    <div class="px-5 py-5">
        <form>
            <h2 class="my-0 pb-2">Introduce el código</h2>
            <div class="text-left mt-2 mb-2">
                <div class="text-sm" variant="text">
                    Introduce el código que hemos enviado a
                    <span
                        v-if="passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.EMAIL">
                        <b>{{ passwordRecoveryStore.recoveryState.email }}</b>
                    </span>
                    <span
                        v-if="passwordRecoveryStore.recoveryState.selectedRecoveryOption == passwordRecoveryStore.recoveryOptions.SMS">
                        <b>+{{ passwordRecoveryStore.recoveryState.phone }}</b>
                    </span>
                    <span>. </span>
                    <a class="underline cursor-pointer" v-on:click="$emit('navPasswordRecovery', $event)">
                        Si este dato es incorrecto cambia la dirección o teléfono al que se envía la recuperación.
                    </a>
                </div>
            </div>
            <InlineMessage severity="error" v-if="newPasswordData$v.otp.$errors.length > 0"
                class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
                <span v-for="error of newPasswordData$v.otp.$errors" :key="error.$uid" class="text-sm">
                    <strong>{{ error.$message }}.</strong>
                </span>
            </InlineMessage>
            <OtpInput class="mt-2" v-model="newPasswordData.otp"
                :class="{ 'p-invalid': newPasswordData$v.otp.$errors.length }" />
            <div class="text-left mt-2 mb-3">
                <div class="text-sm cursor-pointer text-color-secondary" variant="text" disabled="true" :class="{
                    'otpdisabled': newPasswordData.otpTimeReset > 0,
                    'underline': newPasswordData.otpTimeReset < 1
                }" @click="resendOTPCode">
                    <a>
                        Volver a enviar código
                        <span v-if="newPasswordData.otpTimeReset > 0">
                            <span>(en </span>
                            <span>{{ newPasswordData.otpTimeResetData.min }}:</span>
                            <span>{{ newPasswordData.otpTimeResetData.sec }}</span>
                            <span>)</span>
                        </span>
                    </a>
                </div>
            </div>
            <div class="text-left mt-2 mb-2">
                <div class="text-sm" variant="text">
                    Una vez hayas introducido el código correcto, introduce una contraseña para tu cuenta:
                </div>
            </div>
            <InlineMessage severity="error"
                v-if="newPasswordData$v.password.$errors.length > 0 || newPasswordData$v.passwordCheck.$errors.length > 0"
                class="mt-1 p-2 line-height-1 w-full text-left justify-content-start" :icon="false" closable="true">
                <span v-for="error of newPasswordData$v.password.$errors.concat(newPasswordData$v.passwordCheck.$errors)"
                    :key="error.$uid" class="text-sm">
                    <strong>{{ error.$message }}.</strong>
                </span>
            </InlineMessage>
            <span class="mt-2 p-float-label">
                <InputText id="login_password" type="password" v-model="newPasswordData.password"
                    class="w-full p-inputtext-sm" autocomplete="new-password"
                    :class="{ 'p-invalid': newPasswordData$v.password.$errors.length }"
                    @blur="newPasswordData$v.password.$touch" />
                <label for="login_password">Nueva contraseña</label>
            </span>
            <span class="mt-2 p-float-label">
                <InputText id="login_passwordChack" type="password" v-model="newPasswordData.passwordCheck"
                    class="w-full p-inputtext-sm" autocomplete="new-password"
                    :class="{ 'p-invalid': newPasswordData$v.passwordCheck.$errors.length }"
                    @blur="newPasswordData$v.passwordCheck.$touch" />
                <label for="login_password">Repite la contraseña</label>
            </span>
            <span class="mt-2 p-float-label">
                <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full"
                    @click.prevent="changePasswordAction">
                    Cambiar contraseña
                </Button>
            </span>
            <hr class="c-sep-line my-4" />
            <span class="mt-3 p-float-label">
                <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check"
                    v-on:click.prevent="$emit('navLogin', $event)">
                    <b>Volver a iniciar sesión</b>
                </Button>
            </span>
        </form>
    </div>
</template>

<script>
import { reactive, watch, computed, ref } from 'vue';
import OtpInput from '@/components/_common/forms/OtpInput.vue';
import { usePasswordRecoveryStore } from '@/store/data/login/passwordRecovery';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';

import { useVuelidate } from '@vuelidate/core';
import {
    required, minLength, numeric,
    maxLength, sameAs, helpers as vlhelpers
} from '@vuelidate/validators';
import { complexpassword } from '@/helpers/validators/user';
import ServerMessagesAdapter from '@/helpers/validators/utils/serverValidatorMessageConversor';

export default {
    components: {
        OtpInput, InputText, Button, InlineMessage
    },
    emits: [
        'isLoading', 'navLogin', 'navPasswordRecovery', 'navPasswordRecoveryComplete'
    ],
    setup(props, context) {
        const passwordRecoveryStore = usePasswordRecoveryStore();

        const newPasswordDefault = () => ({
            otp: '',
            otpTimeReset: 90,
            otpTimeResetData: {
                min: computed(() => {
                    return String(Math.floor(newPasswordData.otpTimeReset / 60) % 60).padStart(2, '0');
                }),
                sec: computed(() => {
                    return String(newPasswordData.otpTimeReset % 60).padStart(2, '0');
                }),
            },
            password: '',
            passwordCheck: '',
            reset: function () {
                Object.assign(newPasswordData, newPasswordDefault());
            }
        });

        const newPasswordData = reactive(newPasswordDefault());
        // const test = computed(() => { return newPasswordData.password; });
        // Validation
        // Login validation
        const newPasswordData_v = {
            password: {
                required: vlhelpers.withMessage('Introduce la nueva contraseña', required),
                minLength: vlhelpers.withMessage('Contraseña demasiado corta', minLength(8)),
                maxLength: vlhelpers.withMessage('Contraseña demasiado larga', maxLength(60)),
                complexpassword: vlhelpers.withMessage('La contraseña debe tener al menos una mayúscula, una minúscula y un número o caracter especial', complexpassword(8)),
            },
            passwordCheck: {
                required: vlhelpers.withMessage('Repite la contraseña', required),
                sameAs: vlhelpers.withMessage('Las contraseñas no coinciden', sameAs(computed(() => { return newPasswordData.password; }))),
            },
            otp: {
                required: vlhelpers.withMessage('Introduce el código que has recibido', required),
                numeric: vlhelpers.withMessage('El código debe contener sólo números', numeric),
                minLength: vlhelpers.withMessage('El código debe tener 6 dígitos', minLength(6)),
                maxLength: vlhelpers.withMessage('El código debe tener 6 dígitos', maxLength(6)),
            },
        };

        const newPasswordData$er = ref({});
        const newPasswordData$v = useVuelidate(newPasswordData_v, newPasswordData, { $rewardEarly: true, $externalResults: newPasswordData$er, $autoDirty: true });

        // Code timer
        watch(() => newPasswordData.otpTimeReset, async (newData) => {
            if (newData > 0) {
                setTimeout(() => { newPasswordData.otpTimeReset--; }, 1000);
            }
        }, { immediate: true });

        // Code send another one
        const resendOTPCode = async function () {
            context.emit('isLoading', true);
            if (newPasswordData.otpTimeReset < 1) {
                let result = await passwordRecoveryStore.resendRecovery();
                if (result.result < 399) {
                    newPasswordData.otpTimeReset = newPasswordDefault().otpTimeReset;
                }
            }
            context.emit('isLoading', false);
        };

        // Process password change
        const changePasswordAction = async function () {
            newPasswordData$v.value.$clearExternalResults();
            let status = await newPasswordData$v.value.$validate();
            if (status) {
                context.emit('isLoading', true);
                let response = await passwordRecoveryStore.RecoverPassword(newPasswordData.password, newPasswordData.otp);
                if (response.result < 299) {
                    context.emit('navPasswordRecoveryComplete');
                } else if (response.result == 400) {
                    newPasswordData$er.value = { server: ServerMessagesAdapter(response.data) };
                } else if (response.result == 401) {
                    newPasswordData$er.value = { otp: 'El código es incorrecto' };
                } else if (response.result == 429) {
                    newPasswordData$er.value = { otp: 'Has intentado demasiadas veces probar un código. Espera un rato para probar de nuevo' };
                } else {
                    newPasswordData$er.value = { otp: response.message };
                }
            }

            // Stop loading
            context.emit('isLoading', false);
        };

        return {
            newPasswordDefault, newPasswordData, passwordRecoveryStore,
            newPasswordData$v, newPasswordData$er,
            changePasswordAction,
            resendOTPCode, // test,
        };
    },
};
</script>

<style scoped>
.otpdisabled {
    opacity: 40% !important;
}
</style>

