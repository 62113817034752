/**
 * Validate if value is an email.
 * @type {NormalizedValidator}
 */

export const dummyValidator = {
    // eslint-disable-next-line no-unused-vars
    $validator: function(value)  {
        return true;
    },
    $message: '',
};
