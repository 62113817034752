/* eslint-disable no-unreachable */
import { defineStore } from 'pinia';
import { reactive, computed, watch } from 'vue';
import { CookieStorage, LocalStorage } from 'frontend-storage';

import UserSessionService from '@/services/api/data/login/userSession';
import { useUserDataStore } from '@/store/data/user/userData';

export const useSessionStore = defineStore('sessionStore', () => {
    // Stores
    const userDataStore = useUserDataStore();

    // Data
    const authState = {
        UNATHENTITCATED: 0,
        AUTHENTICATED: 100,
    };

    // Use sessionState.user to access this
    const userStateDefault = () => ({
        id: '',
        username: '',
        memberSince: new Date(0).toISOString(),
        memberSinceDate: computed(() => Date.parse(sessionState.user.memberSince)),
        personalInformation: {
            name: '', surname: '', enterprisePosition: '', enterpriseName: ''
        },
        avatar: undefined
    });
    
    const sessionStateDefault = () => ({
        auth: {
            state: authState.UNATHENTITCATED,
            processed: true,
            justRegistered: false,
            data: {
                expires: new Date(0).toISOString(),
                expiresDate: computed(() => Date.parse(sessionState.auth.data.expires)),
                refreshExpires: new Date(0).toISOString(),
                refreshExpiresDate: computed(() => Date.parse(sessionState.auth.data.refreshExpires)),
                refreshToken: '',
                token: ''
            }
        },
        user: computed(() => {
            if(userData?.response?.data != undefined && !userData.error && !userData.loading) {
                return userData.response.data; // API Data (More confiable)
            } else if(loadedUserData.id != undefined && loadedUserData.id.length > 1) {
                return loadedUserData; // Storage cached data
            } else {
                return userStateDefault(); // Default data (no data)
            }
        }),
        clearAuthData: function() {
            Object.assign(sessionState.auth.data, sessionStateDefault().auth.data);
            sessionState.auth.state = authState.UNATHENTITCATED;
        },
        reset: function() {
            Object.assign(sessionState, sessionStateDefault());
        }
    });

    /**
     * Reactive object representing the state of the user session.
     * 
     * @typedef {Object} AuthData
     * @property {string} expires - The expiry date of the authentication token.
     * @property {number} expiresDate - Computed expiry date in milliseconds.
     * @property {string} refreshExpires - The expiry date of the refresh token.
     * @property {number} refreshExpiresDate - Computed expiry date of the refresh token in milliseconds.
     * @property {string} refreshToken - The refresh token.
     * @property {string} token - The authentication token.
     * 
     * @typedef {Object} UserData
     * @property {string} id - User identifier.
     * @property {string} username - Username.
     * @property {string} memberSince - ISO string representation of the member since date.
     * @property {number} memberSinceDate - Computed member since date in milliseconds.
     * @property {Object} personalInformation - Personal information about the user.
     * @property {string} personalInformation.name - User's name.
     * @property {string} personalInformation.surname - User's surname.
     * @property {string} personalInformation.enterprisePosition - User's position in the enterprise.
     * @property {string} personalInformation.enterpriseName - Name of the enterprise.
     * @property {Object} avatar - Avatar in JSON File APi Object. Use with ImageAPIConverter and imageToSrc()
     * 
     * @typedef {Object} SessionState
     * @property {Object} auth - Authentication state object.
     * @property {number} auth.state - Authentication state flag.
     * @property {boolean} auth.justRegistered - Flag indicating if the user has just registered.
     * @property {AuthData} auth.data - Data related to authentication tokens.
     * @property {UserData} user - Computed user data.
     * 
     * - The `auth` property maintains the current authentication state and token information.
     * - The `user` property contains user-specific data, either fetched from the API, loaded from local storage, or default values.
     * - The state includes methods to clear authentication data and reset the session state.
     * 
     * @returns {SessionState} The reactive session state object.
     * @type {SessionState}
     */
    const sessionState = reactive(sessionStateDefault());

    // Data for userData
    const userData = reactive({});
    const loadedUserData = reactive({});

    // Login & Logout
    const login = async function(method, logindata, password, justRegistered = false) {
        sessionState.auth.justRegistered = justRegistered;

        let loginObject = {
            userNameOrEmail: method == 'usernameoremail' ? logindata : undefined,
            phone: method == 'phone' ? logindata : undefined,
            password: password
        };

        let _response = await UserSessionService.login(loginObject);

        if(_response.result == 200) {
            sessionState.auth.state = authState.AUTHENTICATED,
            saveSessionData(_response.data);

            // Save tokens state
            storageSave();

            // Update user data
            updateUserData();
        }

        return _response;
    };

    const logout = async function() {
        userDataClear();
        clearSessionData();
        sessionState.reset();
    };

    // Get bearer
    const getBearer = async function() {
        // If token is not expired, return the actual JWT token
        if(!isTokenExpired(20)) {
            return sessionState.auth.data.token;
        }

        // If token is expired, renew it
        var refreshResult = await refreshToken();

        if(refreshResult) {
            // Refresh is ok. Set to authenticated. 
            if(sessionState.auth.state == authState.UNATHENTITCATED) {
                sessionState.auth.state = authState.AUTHENTICATED;
            }

            return sessionState.auth.data.token;
        } else {
            sessionState.auth.state = authState.UNATHENTITCATED;
            return '';
        }
    };

    // Are we authenticated?
    const isAuthenticated = async function() {
        return sessionState.auth.state == authState.AUTHENTICATED;
    };

    // PRIVATE
    // Save session data
    function saveSessionData(data) {
        // Copy auth state
        sessionState.auth.data.expires = data.auth.expires;
        sessionState.auth.data.refreshExpires = data.auth.refreshExpires;
        sessionState.auth.data.refreshToken = data.auth.refreshToken;
        sessionState.auth.data.token = data.auth.token;

        // Copy user state
        if(Object.keys(loadedUserData).length === 0) {
            Object.assign(loadedUserData, data.user);
        }

        // Save tokens state
        storageSave();
    }

    function clearSessionData() {
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_session');
            lstorage.clear();
        }

        if(CookieStorage.isAvailable) {
            new CookieStorage('hcf_session_token').clear();
            new CookieStorage('hcf_session_refreshToken').clear();
            new CookieStorage('hcf_session_expires').clear();
            new CookieStorage('hcf_session_refreshExpires').clear();
        }
    }

    // Save tokens to storage
    function storageSave() {
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_session');
            lstorage.set('token', sessionState.auth.data.token);
            lstorage.set('refreshToken', sessionState.auth.data.refreshToken);
            lstorage.set('expires', sessionState.auth.data.expires);
            lstorage.set('refreshExpires', sessionState.auth.data.refreshExpires);
        }

        if(CookieStorage.isAvailable) {
            new CookieStorage('hcf_session_token').set('token', sessionState.auth.data.token);
            new CookieStorage('hcf_session_refreshToken').set('refreshToken', sessionState.auth.data.refreshToken);
            new CookieStorage('hcf_session_expires').set('expires', sessionState.auth.data.expires);
            new CookieStorage('hcf_session_refreshExpires').set('refreshExpires', sessionState.auth.data.refreshExpires);
        }
    }

    async function storageLoad(tryRefreshToken = true) {
        let isLoaded = false;

        // First try to load from local storage
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_session');
            let refreshTokenExpires = Date.parse(lstorage.get('refreshExpires'));
            if(refreshTokenExpires > Date.now()) {
                sessionState.auth.data.token = lstorage.get('token');
                sessionState.auth.data.refreshToken = lstorage.get('refreshToken');
                sessionState.auth.data.expires = lstorage.get('expires');
                sessionState.auth.data.refreshExpires = lstorage.get('refreshExpires');
                sessionState.auth.state = authState.AUTHENTICATED;
                isLoaded = true;
            }
        }

        // Second, try to load from cookies
        if(CookieStorage.isAvailable && !isLoaded) {
            let refreshTokenExpires = Date.parse(new CookieStorage('hcf_session_refreshExpires').get('refreshExpires'));
            if(refreshTokenExpires > Date.now()) {
                sessionState.auth.data.token = new CookieStorage('hcf_session_token').get('token');
                sessionState.auth.data.refreshToken = new CookieStorage('hcf_session_refreshToken').get('refreshToken');
                sessionState.auth.data.expires = new CookieStorage('hcf_session_expires').get('expires');
                sessionState.auth.data.refreshExpires = new CookieStorage('hcf_session_refreshExpires').get('refreshExpires');
                sessionState.auth.state = authState.AUTHENTICATED;
                isLoaded = true;
            }
        }

        // Check if token is expired and renew it
        if(tryRefreshToken && isTokenExpired()) {
            var refreshResult = await refreshToken();
            if(!refreshResult) {
                sessionState.auth.state = authState.UNATHENTITCATED;
                sessionState.reset();
                isLoaded = false;
            }
        } else {
            // If not, renew user data only if data is loaded
            if(isLoaded) {
                updateUserData();
            }
            
        }

        return isLoaded;
    }

    // --- Token liftime and session methods ---
    function isTokenExpired(marginsecs = 1) {
        return sessionState.auth.data.expiresDate <= (Date.now() + (marginsecs * 1000));
    }

    // eslint-disable-next-line no-unused-vars
    function isRefreshTokenExpired(marginsecs = 1) {
        return sessionState.auth.data.refreshExpiresDate <= (Date.now() + (marginsecs * 1000));
    }

    async function refreshToken() {
        if(!sessionState.auth?.data?.refreshToken) {
            return false;
        }

        let _response = await UserSessionService.refreshLogin({
            'refreshToken': sessionState.auth.data.refreshToken
        });

        if(_response.result == 200) {
            saveSessionData(_response.data);
            updateUserData();
            return true;
        } else if(_response.result == 401) {
            // Load storage token to avoid deauth on another instance refreshing
            storageLoad(false);
            if(isTokenExpired()) {
                // TODO: deauth
                sessionState.reset();
            }
        } else {
            // Unforseen situation. Do nothing. 
        }

        // Establish just registered to false
        sessionState.auth.justRegistered = false;
        return false;
    }

    // Userdata obtain
    function updateUserData() {
        userDataLoad(); // This load the local storage cache if available
        const userresponse = userDataStore.getUser();
        Object.assign(userData, userresponse);
    } 

    // Cache userdata on server response
    watch(userData, () => {
        let isLoading = userData.loading == true;
        let isError = userData.error != undefined;
        let isPopulated = userData.response?.data != undefined;
        if(isPopulated && (!isLoading) && (!isError)) {
            userDataSave();
        }
    });

    // Userdata cache
    function userDataSave() {
        // We don't save this in cookies
        if (LocalStorage.isAvailable 
        && userData.response != null && userData.response.data != null
        && userData.response.data.id != '') {
            let lstorage = new LocalStorage('hcf_userdata');
            lstorage.set('userdata', userData.response.data);
        }
    }

    function userDataLoad() {
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_userdata');
            let userData = lstorage.get('userdata');
            if(userData?.id?.length > 1) {
                Object.assign(loadedUserData, userData);
            }
        }
    }

    function userDataClear() {
        // Clear local storage load reference (remove all obj keys)
        Object.keys(loadedUserData).forEach((key) => delete loadedUserData[key]);
        Object.keys(userData).forEach((key) => delete userData[key]);

        // Clear local storage data
        if (LocalStorage.isAvailable) {
            let lstorage = new LocalStorage('hcf_userdata');
            lstorage.clear();
        }
    }

    // Computed for easy access
    const isAuthProcessed = computed(() => sessionState.auth.processed);

    // Load on store initialization
    (async () => {
        const results = await Promise.all([
            storageLoad().then(result => ({ success: true, result })).catch(error => ({ success: false, error })),
            userDataLoad().then(result => ({ success: true, result })).catch(error => ({ success: false, error }))
        ]);

        if(results[0].success && results[1].success) {
            sessionState.auth.processed = true;
        }
    })();

    return { 
        sessionState, sessionStateDefault, authState, isAuthProcessed, 
        login, logout, getBearer, isAuthenticated,
        userData, loadedUserData, updateUserData
    };
});