<template>
    <div class="px-5 py-5 text-center">
        <i class="pi pi-check-circle text-green-700 text-6xl mb-3"></i>
        <h2 class="my-0 pb-0">
            ¡Bienvenido, <span class="breakword">{{ userSessionStore.sessionState.user.username }}</span>!
        </h2>
        <div class="text-center mt-0 mb-3">
            Has iniciado sesión correctamente
        </div>
        <span class="mt-2 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm flex justify-content-center w-full" @click.prevent="complete">
                Continuar al sitio (En {{ autoTimer.timer }} seg.)
            </Button>
        </span>
        <span class="mt-1 p-float-label">
            <Button color="primary" class="mt-2 p-button-sm w-full p-button-outlined bg-gray-50
                    p-button-secondary flex justify-content-center" icon="pi pi-check" @click.prevent="logout">
                <b>Cerrar sesión</b>
            </Button>
        </span>
    </div>
</template>

<script>
import { reactive, watch, onMounted } from 'vue';
import { useSessionStore } from '@/store/data/login/userSession';
import Button from 'primevue/button';
import { useRoute } from 'vue-router';
import { isSameDomain } from '@/helpers/url/domainvalidation';
import { redirect as redirectToURL } from '@/helpers/url/redirect';
import router from '@/router';

export default {
    components: {
        Button
    },
    emits: ['isLoading', 'hideLogin'],
    setup(props, context) {
        const userSessionStore = useSessionStore();
        const route = useRoute();

        const complete = async function () {
            // Redirect to continue param if present
            if (route.query.continue != undefined) {
                let continueurl = route.query.continue;
                let actualurl = window.location.href;
                if (isSameDomain(continueurl, actualurl)) {
                    redirectToURL(continueurl);
                }
            }

            // Is component in its own page? (/login)
            if (route.meta?.bindLogin == true) {
                // If it is, we have to redirect to home
                router.replace('/');
            } else {
                // If it is not, we have to hide login component
                context.emit('hideLogin', true);
            }

        };

        const logout = async function () {
            context.emit('isLoading', true);
            await userSessionStore.logout();
            context.emit('isLoading', false);
        };

        // Auto navigation
        const autoTimer = reactive({
            timer: 0,
            timerTimeout: null
        });

        watch(() => autoTimer.timer, (newValue) => {
            if (newValue == 0) {
                complete();
            } else {
                autoTimer.timerTimeout = setTimeout(() => {
                    autoTimer.timer--;
                }, 1000);
            }
        });

        onMounted(() => {
            if (userSessionStore.sessionState.auth.justRegistered) {
                // Register screen is shown, we can perform action automaticaly
                userSessionStore.sessionState.auth.justRegistered = false;
                autoTimer.timer = 0;
                complete();
            } else {
                autoTimer.timer = 10;
            }
        });

        return {
            logout, complete,
            autoTimer,
            userSessionStore
        };
    }
};
</script>

<style scoped>
.breakword {
    overflow-wrap: break-word;
}
</style>