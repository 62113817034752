import { helpers } from '@vuelidate/validators';
import { email } from '@vuelidate/validators';

/**
 * Validate if value is an email.
 * @type {NormalizedValidator}
 */

export const username = {
    $validator: function(value)  {
        if(helpers.req(value)) {
            return /^[a-zA-Z0-9]+$/.test(value);
        } else {
            return true;
        }
    },
    $message: 'Username must be only letters and numbers',
};

/**
 * Validate if value is an email.
 * @type {NormalizedValidator}
 */

export const usernameoremail = {
    $validator: function(value) {
        if(email.$validator(value)) {
            return true;
        } else if(username.$validator(value)) {
            return true;
        } else {
            return false;
        }
    },
    $message: function(_ref) {
        let value = _ref.$model;
        if(value.includes('@')) {
            return 'Introduzca un email válido';
        } else {
            return 'El usuario sólo debe tener letras y números';
        }
    }
};

/**
 * Validate if password is complex
 * @type {NormalizedValidator}
 */
export const complexpassword = (minlength) => { 
    return {
        $validator: function(value)  {
            if(helpers.req(value) && value.length >= minlength) {
                return /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
            } else {
                return true;
            }
        },
        $message: 'Password must have at least one upper case letter, one lower case leter and one number or symbol.',
    };
};

/**
 * Validate if username is already registered
 * @type {NormalizedValidator}
 */
import UserDataService from '@/services/api/data/user/userData';
export const usernameregistered = { 
        $validator: async function(value)  {
            if(helpers.req(value)) {
                let response = await UserDataService.isUsernameTaken(value);
                if(response.result == 200) {
                    return response.data == false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        $message: 'Username is already in use'
};