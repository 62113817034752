import { defineStore } from 'pinia';
import { getBrowserLanguage } from '@/helpers/locale';
import { RequestResult } from '@/services/api';
import PhoneService from '@/services/api/data/phone';
import { sort } from '@/helpers/arrays';

export const usePhoneStore = defineStore('phoneStore', {
    state: () => {
        return {
            countryCodes: [],
            countryCodesSelect: [],
            lastUpdated: new Date('1970-01-01Z00:00:00:000'),
            updateIntervalSeconds: 2592000
        };
    },
    getters: {
        
    },
    actions: {
        // Get the country codes
        async getCountryCodes() {
            await this.updateCountryCodes();
            return this.countryCodes;
        },
        
        // the return type **must** be explicitly set
        async getCountryCodesForSelect() {
            await this.updateCountryCodes();
            return this.countryCodesSelect;
        },

        // TODO: Save countrycodes somewere (localstorage?) to avoid loading on multiple SPAs or reload?
        async updateCountryCodes() {
            // If country codes are in place, force update only if a month passed
            if (this.countryCodes.length > 0 || !this.shouldUpdateCountryCodes()) {
                return;
            }

            // Get browser languages
            const language = getBrowserLanguage();

            // Get country codes
            let _response = await PhoneService.getCountryCodes();
            if (_response.result === RequestResult.SUCCESS) {
                // Update updated time reference
                this.lastUpdated = new Date();

                // Save country codes
                this.countryCodes = _response.data;

                // Map response to form
                let tempCountryCodes = _response.data.map((x, index) => {
                    let _obj = {
                        text: `${x.tag.toUpperCase()} (${x.prefix})`,
                        code: x.prefix,
                        country: x.tag.toLowerCase(),
                        default: false
                    };
                    if (language) {
                        if (language.toLowerCase() === x.tag) {
                            _obj.default = true;
                        }
                    } else if (index === 0) {
                        _obj.default = true;
                    }
                    return _obj;
                });

                // Sort codeOptions with key: text
                this.countryCodesSelect = sort(tempCountryCodes, 'text');
            }
        },

        shouldUpdateCountryCodes() {
            let updateMaxDate = new Date(new Date().getTime() - this.updateIntervalSeconds * 1000);
            return this.lastUpdated < updateMaxDate;
        }
    },
});
