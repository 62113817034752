<script setup>
import { ref, reactive, watch, nextTick, computed } from 'vue';

// Components
import Image from 'primevue/image';
import Button from 'primevue/button';
import Avatar from 'primevue/avatar';
import HeaderUserMenu from '@/components/layout/main/HeaderUserMenu.vue';
import OverlayPanel from 'primevue/overlaypanel';

// Component megamenus
import HeaderCategories from '@/components/layout/main/HeaderCategories.vue';

// Stores
import { useSessionStore } from '@/store/data/login/userSession';
import { useUserPermissionsStore } from '@/store/data/user/userPermissions';
import { useRouter, useRoute } from 'vue-router';

// Bus component
import useEmitter from '@/helpers/composables/useEmitter';

// Helpers
import { getRepresentativeLetters, toPastel } from '@/helpers/strings';
import { imageToSrc } from '@/helpers/api/imageAPIConverter';

// Event bus
const emitter = useEmitter();

// Routes
const router = useRouter();
const route = useRoute();

// Stores
const userSessionStore = useSessionStore();

// Show dialog
const showLoginDialog = () => {
    isMobileMenuOpen.value = false;
    nextTick(() => emitter.emit('show-login-dialog'));
};

// Show user menu
const header_user_menu = ref(null);
const toggleUserMenu = function($ev) {
    header_user_menu.value.toggleMenu($ev);
};

// Menu control
const isMobileMenuOpen = ref(false);
const isCategoryMenuOpen = ref(false);

// Megamenus
const megaCategories = ref(null);

// Megamenus breakpoints
const megaBreakpoints = { '100000px': '580px', '992px': '100vw' };

// Computed reducing username to 14 characters and if more adds three points
const getReducedUsername = computed(() => {
    if (userSessionStore.sessionState?.auth?.state == userSessionStore.authState.AUTHENTICATED) {
        return userSessionStore.sessionState.user.username.length > 14 ?
            userSessionStore.sessionState.user.username.substring(0, 14) + '...' :
            userSessionStore.sessionState.user.username;
    }
    return '';
});

// We need to discard mobile menu when route page changes
watch(() => route.path, (oldp, newp) => {
    if(oldp != newp) {
        isMobileMenuOpen.value = false;
        isCategoryMenuOpen.value = false;
    }
});

</script>

<template>
    <div class="flex align-items-center flex-column topbar-background shadow-6">
        <div class="pagecontainedwidth headercontainer px-2 flex align-items-center justify-content-between relative lg:static">
            <RouterLink to="/">
                <div class="relative pl-2 lg:pl-0">
                    <!-- ALPHA Text in front of logo -->
                    <span class="absolute border-round opacity-80 top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">ALPHA</span>
                    <Image :src="'/brand/logo/MainLogoNegative.png'"
                        :srcset="'/brand/logo/MainLogoNegative@0.5x.png' + ' 0.5x ' +
                            '/brand/logo/MainLogoNegative@2x.png' + ' 2x '"
                        height="34" alt="Logo de Holachollo" 
                        class="mr-0 lg:mr-6"/>
                </div>
            </RouterLink>
                
            
            <!-- Right part for desktop -->
            <div id="header_optionlist" :class="{ 
                        'block': isMobileMenuOpen, 'bg-black-alpha-70': isMobileMenuOpen , 'z-5': isMobileMenuOpen,}"
                        class="align-items-center h-auto lg:h-full flex-grow-1 justify-content-between hidden lg:flex 
                        absolute lg:static w-full left-0 top-100 z-5 shadow-2 lg:shadow-none 
                        p-3 lg:p-0">
                
                <!-- Left side -->
                <div class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                    <li>
                        <RouterLink to='/' :key="Date.now()" @click="isMobileMenuOpen = false;">
                            <a v-ripple :class="{'rotate-90-icon': isMobileMenuOpen }"
                                class="text-white bg-secondary-hover hover:text-black flex px-6 p-3 
                                    lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer 
                                    transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-chart-pie mr-2"></i>
                                <span>Últimos chollos</span>
                            </a>
                        </RouterLink>
                    </li>
                    <li>
                        <a v-ripple @click="isMobileMenuOpen = false; megaCategories.toggle($event);"
                                :class="{'rotate-90-icon': isMobileMenuOpen }"
                                class="text-white bg-secondary-hover hover:text-black flex px-6 p-3
                                    lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer 
                                    transition-colors transition-duration-150 p-ripple"                           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-sitemap mr-2"></i>
                            <span>Categorías</span>
                            <i class="pi pi-rotate-this pi-angle-down ml-auto lg:ml-3"></i>
                        </a>
                    </li>
                </div>
                    
                <!-- Right side -->
                <div>
                    <!-- Authenticated -->
                    <div class="list-none h-auto lg:h-full p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row
                                fixed lg:relative right-0 top-0 mr-2 lg:mr-0 avatarElemHeader">
                        <div v-if="userSessionStore.sessionState.auth.state == userSessionStore.authState.AUTHENTICATED"
                            @click="toggleUserMenu" 
                            class="list-none bg-white-alpha-10 hover:bg-white-alpha-20 cursor-pointer h-full p-0 m-0 flex align-items-center select-none 
                            flex-row lg:flex-row mt-2 lg:mt-0 border-round-3xl">
                            <div class="text-white pr-2 pl-3 text-sm flex-column flex-wrap">
                                <div>Hola, {{ getReducedUsername }}</div>
                                <div class=" text-xs">Bienvenido</div>
                            </div>
                            <Avatar :label="imageToSrc(userSessionStore.sessionState.user.avatar).src ? 
                                        null : getRepresentativeLetters(userSessionStore.sessionState.user.username, 2)"
                                    :image="imageToSrc(userSessionStore.sessionState.user.avatar).src"
                                    :style="{'background-color': toPastel((userSessionStore.sessionState.user.username))}"
                                    class="avatar-header  cursor-pointer colavatar" shape="circle" />
                        </div>

                        <!-- User menu -->
                        <HeaderUserMenu ref="header_user_menu" />
                    </div>

                    <!-- Unauthenticated -->
                    <div v-if="userSessionStore.sessionState.auth.state == userSessionStore.authState.UNATHENTITCATED"
                        class="list-none py-3 lg:p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row ">
                        <div class="flex-grow-1 px-3 lg:px-0">
                            <Button label="Regístrate / Inicia sesión" severity="primary" size="small"
                                    rounded class="w-full lg:w-auto py-2 lg:py-2 text-base lg:text-sm" 
                                    @click="showLoginDialog" />
                        </div>
                    </div>

                </div>

            </div>

            <!-- Right part for mobile -->
            <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple mr-2"
                @click="isMobileMenuOpen = !isMobileMenuOpen">
                <i class="pi pi-bars text-3xl"></i>
            </a>

            <!-- Cover all screen and prevent scronn on Mobile Menu Open -->
            <transition name="fade">
                <div v-if="isMobileMenuOpen || isCategoryMenuOpen" 
                    :class="{'lg:hidden': !isCategoryMenuOpen }"
                    class="fixed top-0 left-0 w-full h-full bg-black-alpha-50 z-4"
                    @click="isMobileMenuOpen = false"></div>
            </transition>

            <!-- Header categories -->
            <OverlayPanel ref="megaCategories" class="noarrow" :breakpoints="megaBreakpoints"
                @show="isCategoryMenuOpen = true" @hide="isCategoryMenuOpen = false">
                <HeaderCategories />
            </OverlayPanel>
            
        </div>
    </div>
</template>

<style>
.headergrid {
    background: #000411;
}

.headercontainer {
    height: 60px;
}
</style>

<style scoped>
.button-square {
  width: 40px;
  height: 40px;
}

.avatar-header {
    height: 40px;
    width: 40px;
}

.smallmenuavatar {
    font-size: 12px;
}

.colavatar .p-avatar-text {
    font-size: 0.8em;
}

.rotate-90-icon i.pi-rotate-this {
    transform: rotate(-90deg);
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.topbar-background {
    background: rgb(8,10,32);
    background: linear-gradient(156deg, rgba(8,10,32,1) 31%, rgba(41,41,41,1) 87%, rgba(29,27,22,1) 100%);
}

.positioner_user_menu_style {
    height: 0.1px;
    width: 0.1px;
    color: transparent;
}
</style>
